.Planning > .Table {
  display: flex;
  flex-direction: column;
  outline: none;
}
.Planning > .Table > header {
  display: flex;
  height: 40px;
  background-color: #cacbcd;
  font-weight: 700;
}
.Planning > .Table > .Grid {
  outline: none;
}
.Planning > .Table .Name {
  width: 150px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  border-right: 1px solid black;
}
.Planning > .Table > header > .Day {
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Planning .ShiftCondition .icon {
  font-size: 16px;
  cursor: pointer;
}
.Planning .Cell.isEven {
  background: #fafafa;
}
.Planning .Cell.isWeekend {
  background: #ebebeb;
}
.Planning .Cell.isWeekend.isEven {
  background: #e6e6e6;
}
.Planning .Cell.isLastQualifiedCaregiver {
  border-bottom: 1px solid black;
}
.Planning .ShiftCondition.isHighlighted {
  background: #E7F7FD;
}
.Planning .ShiftCondition:hover,
.Planning .ShiftCondition.isPopupOpened {
  background: rgba(1,1,1,0.1);
}
.Planning .ShiftCondition.isDragging {
  cursor: copy;
}
.Planning .ShiftCondition .ConditionKind {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
}
.Planning .ShiftCondition .ConditionKind > .button {
  position: absolute;
  right: 0;
  top: 1px;
  font-size: 3px;
  background: none;
  visibility: hidden;
  cursor: pointer;
}
.Planning .ShiftCondition .ConditionKind:hover > .button {
  visibility: visible;
}
.Planning .ShiftCondition .ConditionKind > .button > .icon {
  padding-top: 5px;
}
.Planning .ShiftCondition .ConditionKind:hover > .button:hover > .icon {
  font-size: 18px;
}
.Planning > a > .button {
  float: right;
  margin: 0;
}
.ShiftConditionPopup > .ui.form > .DayTypes {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ShiftConditionPopup > .ui.form > .DayTypes > .label {
  cursor: pointer;
  opacity: 0.4;
}
.ShiftConditionPopup > .ui.form > .DayTypes > .label:hover,
.ShiftConditionPopup > .ui.form > .DayTypes > .label.isActive {
  opacity: 1;
}
