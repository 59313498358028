/*
As there are some builtin CSS provided by Semanctic-ui for .ui.grid,
we had to use .Error.ui.grid instead of .Error to overwrite builtin CSS
*/

.Error.ui.grid {
    height: calc(100vh - 68px);
    margin: 0;
    background-color: #ffffff;
}
.Error.ui.grid .value {
    color: #db2828 !important;
}
.Error.ui.grid .label {
    color: #db2828 !important;
}
.Error.ui.grid .ui.negative.message p {
    margin-top: 10px;
}
.Error.ui.grid .ui.negative.message button {
    padding: 10px 15px;
    font-size: 13px;
    margin: 15px 0px
}
