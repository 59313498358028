.GridRow {
  position: relative;
  display: flex;
  flex-direction: row;
}
.GridColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.MonthDaysContainer {
  display: flex;
  flex-direction: row;
  flex: 0 0 75px;
  z-index: 10;
  color: #3F5F7A;
  background-color: #FFFFFF;
}
.CaregiverList {
  overflow: hidden !important;
  outline: none;
}
.HeaderGrid {
  width: 100%;
  overflow: hidden !important;
  outline: none;
}
.Days {
  width: 100%;
  outline: none;
}
.Title,
.SearchField,
.Caregiver {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  border: 1px solid #F6F6F6;
}
.Caregiver {
  color: #ffffff;
}
.QualifiedCaregiver {
  background: #995FA3;
}
.NonQualifiedCaregiver {
  background: #5F62A3;
}
.Caregiver.isEvenRow {
  background-color: #FCFCFC;
}
.QualificationGroup {
  position: absolute;
  left: 3px;
  top: 0px;
  font-size: 8px;
}
.CaregiverName {
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.SearchField input {
  padding: 7px 10px !important;
  border: none !important;
  border-radius: 0px !important;
}
.Title {
  background-color: #3F5F7A;
  color: #FFFFFF;
  border-top: none;
}
.MonthHeaderCell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #3F5F7A;
  color: #FFFFFF;
  border-bottom: 1px solid #F6F6F6;
  border-right: 1px solid #F6F6F6;
  border-left: 1px solid #F6F6F6;
}
.MonthHeaderCell:first-of-type {
  border-left-width: 1px;
}
.DayHeaderCell {
  color: #111111;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  border: 1px solid #F6F6F6;
}
.DayHeaderCell.isWeekend {
  background-color: #DEE7EE;
}
.DayHeaderCell > span {
  margin-right: 10px;
}
.DayCellContent {
  cursor: pointer;
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border: 1px solid #F6F6F6;
  background-color: #FFFFFF;
  color: #000066;
}
.DayCellContent > .PlannedTourDropdown {
  min-width: auto !important;
  max-width: 100%;
  width: 100%;
  height: 100%;
  min-height: 100% !important;
  line-height: 100% !important;
}
