.TimePicker {
  width: 7em !important;
}
.small,
.tiny,
.mini {
  width: inherit !important;
}
.small input,
.tiny input,
.mini input {
  padding: 0px !important;
}
.Input {
  margin-top: 2px;
}
