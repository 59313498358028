.Caregivers {
  width: 100%;
}
.Caregivers > .Headers {
  width: 100%;
  font-weight: 900;
  padding: 0px 10px;
  background-color: #cacbcd;
  height: 60px;
}
.Caregivers > .Headers div:nth-child(-n+4) {
  padding-left: 13px;
}
.Caregivers > .Headers div:nth-child(n+5) {
  text-align: center;
}
.Caregivers > a > .button {
  float: right;
  margin-top: 10px;
  margin-right: 0px;
}
.Caregivers > .CaregiversList {
  height: calc(100% - 115px);
  overflow-y: scroll;
}
.Caregivers > .CaregiversList > .Caregiver {
  width: 100%;
  height: 50px;
}
.Caregivers > .CaregiversList > .Caregiver:nth-child(2n) {
  background-color:  #fafafa;
}
.Caregivers > .CaregiversList > .button {
  margin-top: 10px;
  margin-right: 31px;
}
.Caregivers > .CaregiversList > .Caregiver.LastQualifiedCaregiver {
  border-bottom: 1px solid black;
}
.Caregivers > .Headers,
.Caregivers > .CaregiversList > .Caregiver {
  display: grid;
  grid-template-columns: 0.5fr 3fr 3fr 2fr 1fr 1fr 1fr 1fr 1fr 2fr 2fr;
  grid-column-gap: 10px;
  align-items: center;
}
