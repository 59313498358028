.Stations {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}
.Grid {
  margin: 0 !important;
  overflow-y: scroll;
  width: 100% !important;
}
