.ShiftOptimizer > .ActionBar {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ShiftOptimizer > .ActionBar > .circular.button {
  margin-right: 10px;
}
.ShiftOptimizer > .ActionBar > .input {
  min-width: 250px;
  margin-right: 10px;
}
.ShiftOptimizer > .ActionBar > .ui.primary.button {
  margin-right: 0px;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults {
  display: flex;
  flex-direction: column;
  outline: none;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > header {
  display: flex;
  height: 40px;
  background-color: #cacbcd;
  font-weight: 700;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Table,
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Footer {
  outline: none;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults .Name {
  width: 150px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  border-right: 1px solid black;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > header > .Day {
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Table > div > .Cell.isEven {
  background: #fafafa;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Table > div > .Cell.isWeekend {
  background: #ebebeb
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Table > div > .Cell.isWeekend.isEven {
  background: #e6e6e6;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Table > div > .Cell.isLastQualifiedCaregiver {
  border-bottom: 1px solid black;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Footer {
  background-color: #E7F7FD;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Footer > div > .Cell > .Title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  border-right: 1px solid black;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Footer > div > .Cell > .Title > .icon {
  color: red;
  margin-right: 7px;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Footer > div > .Cell > .Title > span {
  font-weight: bold;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults .DayShiftPlan {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults .DayShiftPlan:hover {
  background: rgba(1,1,1,0.1);
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults .DayShiftPlan > .icon {
  position: absolute;
  top: 0;
  right: 0;
  color: red;
  font-size: 10px;
}
.ui.popup.ActionBarPopup {
  width: 500px;
  height: 300px;
  overflow-y: auto;
  padding: 10px 20px;
}
.ui.popup.ActionBarPopup > .ShiftOptimizerParameters > .field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}
.ui.popup.ActionBarPopup > .ShiftOptimizerParameters > .field > .ui.input {
  width: 70px;
  margin-left: 10px;
}
.ui.popup.ActionBarPopup > .ConstraintViolations > .Constraints,
.ui.popup.DayShiftPlanPopup > .Content > .Constraints {
  color: #F3553C;
}
