/**
* ----------------------------------------
* animation slide-bottom
* ----------------------------------------
*/
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}
.Client {
  padding: 0 10px;
  color: #3F5F7A;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.Client:hover {
  background: white;
}
.Client.isExpanded {
  background: white;
}
.ClientDescription {
  display: grid;
  grid-template-columns: 180px 60px 200px 1fr 250px 1fr 1fr 70px 80px;
  align-items: center;
  height: 60px;
  cursor: pointer;
  font-size: 18px;
}
.Client:hover {
  background: white;
}
.Client.isExpanded {
  background: white;
}
.Client.isExpanded > .ClientDetails {
  width: 100%;
  height: 100%;
  display: flex;
  grid-column: span 6;
  justify-content: space-between;
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.Client.isCollapsed > .ClientDetails {
  display: none;
  -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.ActionButtons {
  visibility: hidden;
  margin: 0 !important;
}
.Client:hover > .ClientDescription > .ActionButtons {
  visibility: visible;
}
.Client.isCollapsed > .ClientDescription > .ActionButtons {
  transition: transform .5s ease-in-out;
}
.Client.isExpanded > .ClientDescription > .ActionButtons {
  visibility: visible;
  transition: transform .5s ease-in-out;
}
.ClientDetails > div {
  width: 48%;
  margin: 20px 0px;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.15);
}
.ClientDetails > div > div:first-child {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #00B0E7 17.19%, #0098C7 100%);
  padding-left: 20px;
  height: 40px;
  color: white;
  font-size: 18px;
}
.ClientDetails > div > div:nth-child(2) {
  padding: 5px 15px;
}
.ActionButtons {
  display: flex;
}
