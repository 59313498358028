.StationSelect::before {
  opacity: 0; /* hides underline as long as station picker is not focused */
}
.StationSelect > :global(.MuiSelect-select):focus {
  background-color: transparent;
}
.StationSelect > :global(.MuiSelect-icon) {
  opacity: 0.3;
  transition: opacity 0.1s;
}
.StationSelect:hover > :global(.MuiSelect-icon),
.StationSelect:global(.Mui-focused) > :global(.MuiSelect-icon) {
  opacity: 1;
}

