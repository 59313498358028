.ClientVisits {
  max-width: 1200px;
  margin: 25px auto 35px auto;
  display: flex;
  flex-direction: column;
}
.ClientVisits > .Header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0px 20px;
}
.ClientVisits > .Header h3 {
  color: #0D6FBA;
  margin-bottom: 5px;
}
.ClientVisitsContainer {
  flex-grow: 1;
  overflow-y: scroll;
  height: 0;
}
.ClientVisits .ClientVisitsContainer > ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  border: 0.5px solid rgba(34, 36, 38, .15);
}
.ClientVisits .ClientVisitsContainer > ul > li {
  border: 0.5px solid rgba(34, 36, 38, .15);
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
}
.ClientVisits .ClientVisitsContainer > ul > li > h4 {
  border-bottom: 1px solid rgba(34, 36, 38, .15);
  padding: 1em;
  margin-bottom: 0px;
}
.ClientVisits .ClientVisitsContainer > ul > li > ul {
  border-bottom: 1px solid rgba(34, 36, 38, .15);
  list-style: none;
  padding: 5px;
}
.ClientVisits .ClientVisitsContainer > ul > li > ul > li {
  text-align: left;
}
.ClientVisits .ClientVisitsContainer .Services {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
}
.ClientVisits .Services.actual,
.ClientVisits .Legend li.actual {
  background-color: #00b33c;
}
.ClientVisits .Services.planned,
.ClientVisits .Legend li.planned {
  background-color: #cca300;
}
.ClientVisits .Services.planned-unassigned,
.ClientVisits .Legend li.planned-unassigned {
  background-color: #c0c0c0;
}
.ClientVisits .Services li:nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1;
  padding-right: 5px;
}
.ClientVisits .Services li:nth-child(2) {
  grid-column: 2 / 2;
  grid-row: 1;
  text-align: right;
}
.ClientVisits .Services li:nth-child(3) {
  grid-column: 1 / 3;
  grid-row: 2;
}
.ClientVisits .Services li:nth-child(4) {
  grid-column: 1 / 3;
  grid-row: 3;
  padding-top: 7px;
}
.ClientVisits .Legend {
  display: block;
  max-width: 200px;
  margin-top: 10px;
}
.ClientVisits .Legend li {
  align-items: center;
  border-bottom: 1px solid rgba(34, 36, 38, .15);
  display: flex;
  height: 40px;
  list-style: none;
  padding-left: 0.5em;
}
.NoVisits {
  padding: 30px 0px;
  text-align: center;
}
