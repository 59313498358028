.PlannedActivity {
  overflow: visible !important; /* The selection count is overflowing */
  padding: 5px;
  position: relative;
  --drag-color: #3F5F7A;
}
.PlannedActivity:focus {
  outline: 0;
}
.PlannedActivity.IsSelected {
  color: #ffffff;
  background: #3F5F7A;
}
.IsRescheduled {
  opacity: 0.5;
}
.IsRescheduledPlannedVisitDeleted {
  background: #ff4d4d !important;
}
.Header {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 40px auto;
  height: 44px;
  justify-content: space-between;
}
.PlannedVisitHeader {
  display: grid;
  grid-template-columns: auto 20px;
}
.Name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.Code {
  font-weight: bold;
  margin-right: 4px;
  text-transform: uppercase;
}
.PlannedVisitIcons {
  align-items: center;
  color: #ffd700;
  display: flex;
  justify-content: center;
}
.Time {
  display: grid;
  font-weight: bold;
  line-height: 1em;
  position: relative;
  grid-template-rows: 1fr 1fr 1fr;
  text-align: right;play: flex;
}
.TimeDisabled {
  color: #c0c0c080;
}
.MissingLocationWarning {
  align-items: center;
  bottom: 0;
  display: flex;
  color: #aa0000;
  justify-content: center;
  left: 0;
  padding-left: 10px;
  position: absolute;
  right: 0;
  top: 0;
}
.Index {
  background-color: silver;
  border-radius: 50%;
  display: flex;
  font-size: 10px;
  justify-content: center;
  margin-left: 4px;
  width: 20px;
}
.Qualification .Index {
  background-color: var(--unknown-qualification-dark-color);
}
.RequiresQualifiedCaregiver .Index {
  background-color: var(--qualified-caregiver-color);
}
.RequiresCareCompanion .Index {
  background-color: var(--care-companion-color);
}
.RequiresCareCompanionWithSgbVOnlyServices .Index {
  background-color: var(--care-companion-sgb-v-only-services-color);
}
.StartOffset {
  color: darkred;
}
.AdditionalDrivingTimeIndicator {
  color: blue;
  margin: 0px !important;
  position: absolute;
  right: -10px;
  top: -4px;
}
.Qualification {
  border-left: 5px solid var(--unknown-qualification-color);
}
.RequiresQualifiedCaregiver {
  border-left-color: var(--qualified-caregiver-color);
}
.RequiresCareCompanion {
  border-left-color: var(--care-companion-color);
}
.RequiresCareCompanionWithSgbVOnlyServices {
  border-left-color: var(--care-companion-sgb-v-only-services-color);
}
.RejectedOrCancelled {
  opacity: 0.3;
}
.ExpandButton {
  display: none !important;
  left: 50%;
  margin-left: auto;
  position: absolute !important;
  top: 34px;
  transform: translate(-50%, 0) rotate(0deg);
}
.ExpandButton.IsExpanded {
  transform: translate(-50%, 0) rotate(180deg);
}
.PlannedActivity :global(.MuiCollapse-container) {
  padding: 8px 0;
}
.PlannedActivity:hover .ExpandButton {
  display: inline-block !important;
}
.IsSelected .StartOffset {
  color: #ffffff;
}
.SelectionCount {
  right: -15px;
  top: -15px;
  color: #ffffff;
  background: var(--drag-color);
  border-radius: 50%;
  height: 25px;
  width: 25px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
}
.TourOrderLink {
  margin-bottom: 5px;
}
.HistoricalTimes, .PlannedTimes {
  display: grid;
  grid-template-areas: "title times";
  grid-template-columns: auto 1fr;
}
.PlannedTimes {
  margin-bottom: 6px;
}
.HistoricalTimes span:nth-child(1), .PlannedTimes span:nth-child(1) {
  font-weight: bold;
}
.HistoricalTimes span:nth-child(2), .PlannedTimes span:nth-child(2) {
  margin-right: 4px;
  text-align: right;
}
.ServicesTitle {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 9px !important;
  display: block;
}
.ServiceAbbreviation {
  font-weight: bold;
}
.Service {
  list-style: circle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.DrivingDurationAndVisitDuration {
  display: flex;
  flex-flow: row;
  margin-bottom: 6px;
}
.Duration, .DrivingTime {
  display: flex;
  flex-flow: row;
  font-size: 12px;
  margin-right: 4px;
}
.HintIcon {
  margin-left: auto;
}
.AdditionalDrivingDuration {
  align-items: center;
  display: flex;
}
.AdditionalDrivingDurationEditor {
  margin-left: 5px !important;
  margin-right: 2px !important;
  width: 40px !important;
}
.AdditionalDrivingDurationEditor input {
  text-align: right;
}
.IdCopy {
  bottom: 2px;
  position: absolute !important;
  right: 2px;
}
.PlannedBreakHeader {
  align-items: center;
  display: flex;
}
.DurationEditor {
  margin-left: 4px !important;
  margin-right: 4px !important;
  width: 40px !important;
}
.DurationEditor input {
  text-align: right;
}
.PlannedGenericActivityDuration {
  align-items: center;
  display: flex;
}
