.Tasks {
  background: white;
  height: 100%;
}
.Tasks .Subheader {
  border-bottom: 1px solid grey;
  padding: 7px;
  height: 40px;
}
.Tasks .Subheader .Filter.menu {
  height: 296px;
  width: 250px;
}
.Tasks .Subheader .item.active {
  font-weight: 900;
}
.Tasks .Subheader .menu > .header {
  font-size: unset;
}
.Tasks .Subheader .UserDropdown {
  width: 100%;
  border-radius: 0% !important;
}
.Tasks .Subheader .UserDropdown .visible.menu.transition {
  position: absolute;
  top: 36px !important;
  left: 6px;
  height: 150px;
  box-shadow: none;
  border-radius: 0% !important;
}
.Tasks .Subheader .label {
  background-color: #4AAFE2;
  color: white;
  margin-left: 10px;
}
.Tasks .Subheader .Sort {
  display: flex;
  float: right;
}
.Tasks .Subheader .Sort > .icon {
  margin-top: 3px;
  margin-right: 0px;
  cursor: pointer;
}
.Tasks .Subheader .Sort .ui.button.active {
  background-color: #4AAFE2;
}
.Tasks .TaskList {
  overflow-y: scroll;
  height: calc(100% - 40px);
}
