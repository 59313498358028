.LoggedInUser {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    padding: 10px;
    background-color: #00B0E7;
    text-align: left;
    color: #111111;
    display: flex;
    align-items: center;
}
.LoggedInUser span {
    margin-left: 4px;
}
.LoggedInUser .button.logout {
    background-color: transparent !important;
    color: #111111;
    right: 0;
    float: right;
}
.avatar {
    margin: 0;
    width: 30px;
    height: 30px;
    border-radius: 18px;
    border: solid 2px white;
    margin-right: 10px;
}
.LoggedInUser > img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid white;
}
.LoggedInUser > label {
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    font-size: 1.2em;
    margin-left: 6px;
}

.MenuWithDropdown_MenuWithDropdown__q9E2d {
  color: #ffffff !important;
}
.MenuWithDropdown_MenuWithDropdown__q9E2d a {
  color: black;
}
.MenuWithDropdown_Active__1Y-UG {
  background-color: rgba(0,0,0, 0.18) !important;
}
.MenuWithDropdown_DropdownItem__3Co-5:hover {
  background-color: rgba(0,0,0, 0.1) !important;
}

.Header_Header__3hxnA {
  background-color: #00b0e7 !important;
}
.Header_Home__fNOd1 {
  align-items: end;
  display: grid;
  grid-template: 40px / auto auto auto;
  margin-right: 70px !important;
}
.Header_Logo__2etfY {
  height: 40px !important;
}
.Header_MenuButton__3vLo0 {
  color: #fff !important;
  font-size: 13px;
}
.Header_ProfileLink__2KF39 {
  margin-left: auto;
}
.Header_Title__3x1K8 {
  margin-bottom: -3px !important;
}
.Header_Version__1jPZF {
  padding-left: 3px;
} 

/*
As there are some builtin CSS provided by Semanctic-ui for .ui.grid,
we had to use .Error.ui.grid instead of .Error to overwrite builtin CSS
*/

.Error.ui.grid {
    height: calc(100vh - 68px);
    margin: 0;
    background-color: #ffffff;
}
.Error.ui.grid .value {
    color: #db2828 !important;
}
.Error.ui.grid .label {
    color: #db2828 !important;
}
.Error.ui.grid .ui.negative.message p {
    margin-top: 10px;
}
.Error.ui.grid .ui.negative.message button {
    padding: 10px 15px;
    font-size: 13px;
    margin: 15px 0px
}

.App_App__GTNKG {
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  flex-flow: column !important;
  overflow-y: hidden !important;
}
.App_App__GTNKG > *:not(:first-child) {
  flex-grow: 1; /* Use full height for the embedded route component */
  width: 100%;
  height: 0;
}

.App\.colors_Colors__1LUGj {
  --care-companion-color: #fca17d;
  --care-companion-sgb-v-only-services-color: #CB8090;
  --qualified-caregiver-color: #995fa3;
  --unknown-qualification-color: #ffffff;
  --unknown-qualification-dark-color: #b8c5d0;
}

.ErrorDialog {
  color: red;
}
ul {
  list-style: none;
}

.UnwantedServicesTable_UnwantedService__ccMx9 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ServiceSelector_ServiceSelector__aNt5W {
  width: 400px;
}

.unwanted-services_Header__12u6x {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.unwanted-services_Header__12u6x h4 {
  margin-bottom: 0;
}


.WorkingTimesTable_WorkingTime__3yhPI {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desired-working-times_Header__329Yk {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.desired-working-times_Header__329Yk h4 {
  margin-bottom: 0;
}

.caregiver-profile_CaregiverProfile__3KD_e {
  display: flex;
  justify-content: center;
}
.caregiver-profile_Avatar__2Zkwq {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.unwanted-clients_UnwantedClients__2MJ2v {
  width: 100%;
}
.unwanted-clients_UnwantedClientsHeader__26PdB {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: 900;
  padding-left: 10px;
  margin-bottom: 0;
}
.unwanted-clients_UnwantedClientsList__3v8tL {
  overflow-y: auto;
  max-height: 400px;
  border: 1px solid rgba(34,36,38,.1);
  background: white;
}
.unwanted-clients_UnwantedClient__hxzZk {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(34,36,38,.1);
  padding: 0px 10px;
}

.Caregivers_Caregivers__18Oo9 {
  width: calc(100vw - 20px);
  height: calc(100vh - 52px);
  display: grid;
  grid-template-rows: 100px auto;
  grid-row-gap: 20px;
  margin: 0px 10px;
}
.Caregivers_CaregiversSearch__wYvVJ {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Caregivers_Dropdown__VO32k {
  width: 400px !important;
}
.Caregivers_Caregiver__283Jp {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  grid-column-gap: 10px;
}

.StationPicker > .list  {
  min-width: 200px;
  margin-top: 0px;
  font-size: 16px;
}
.StationPicker > .list > a > .item {
  padding: 5px;
}
.StationPicker > .list > a > .item:hover {
  background-color: #fafafa;
}

.caregivers_CaregiversHome__flfCQ {
  background-color: #ffffff;
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/**
* ----------------------------------------
* animation slide-bottom
* ----------------------------------------
*/
@keyframes client_slide-top__ZLaIc {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(30px);
  }
}
.client_Client__MItNW {
  padding: 0 10px;
  color: #3F5F7A;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.client_Client__MItNW:hover {
  background: white;
}
.client_Client__MItNW.client_isExpanded__V08oW {
  background: white;
}
.client_ClientDescription__1dir4 {
  display: grid;
  grid-template-columns: 180px 60px 200px 1fr 250px 1fr 1fr 70px 80px;
  align-items: center;
  height: 60px;
  cursor: pointer;
  font-size: 18px;
}
.client_Client__MItNW:hover {
  background: white;
}
.client_Client__MItNW.client_isExpanded__V08oW {
  background: white;
}
.client_Client__MItNW.client_isExpanded__V08oW > .client_ClientDetails__22JMK {
  width: 100%;
  height: 100%;
  display: flex;
  grid-column: span 6;
  justify-content: space-between;
  animation: client_slide-bottom__qXeiQ 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.client_Client__MItNW.client_isCollapsed__1OWMW > .client_ClientDetails__22JMK {
  display: none;
  animation: client_slide-top__ZLaIc 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.client_ActionButtons__2cezD {
  visibility: hidden;
  margin: 0 !important;
}
.client_Client__MItNW:hover > .client_ClientDescription__1dir4 > .client_ActionButtons__2cezD {
  visibility: visible;
}
.client_Client__MItNW.client_isCollapsed__1OWMW > .client_ClientDescription__1dir4 > .client_ActionButtons__2cezD {
  transition: transform .5s ease-in-out;
}
.client_Client__MItNW.client_isExpanded__V08oW > .client_ClientDescription__1dir4 > .client_ActionButtons__2cezD {
  visibility: visible;
  transition: transform .5s ease-in-out;
}
.client_ClientDetails__22JMK > div {
  width: 48%;
  margin: 20px 0px;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.15);
}
.client_ClientDetails__22JMK > div > div:first-child {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #00B0E7 17.19%, #0098C7 100%);
  padding-left: 20px;
  height: 40px;
  color: white;
  font-size: 18px;
}
.client_ClientDetails__22JMK > div > div:nth-child(2) {
  padding: 5px 15px;
}
.client_ActionButtons__2cezD {
  display: flex;
}

.HealthInsuranceGroup_HealthInsuranceGroup__2GK6e {
  background-color: #E9E9E9 !important;
  font-size: 12px;
  width: 70px;
}


.ClientModal_ClientModal__1VcdM{
  max-width: 100%;
  margin-right: 0 !important;
  background: #f2f2f2;
}
.ClientModal_Visible__3N_xF{
  opacity: 1;
}
.ClientModal_Hidden__j9EDJ{
  opacity: 0;
}
.ClientModal_Header__1kAh_{
  display: block;
  text-align: center;
}
.ClientModal_Header__1kAh_ > h3 {
  font-size: 30px;
  padding-top: 17px;
  margin-top: 0px;
}
.ClientModal_Nurse__bKlW- {
  max-width: 100px !important;
  margin-top: 37px;
}
.ClientModal_Content__3sABm{
  margin: 40px 98px;
}
.ClientModal_Content__3sABm > div > div > div > div {
  width: 100%;
}
.ClientModal_Row__83Ots {
  display: flex;
  margin-top: 25px;
  align-items: left;
  justify-content: space-between;
  text-align: left;
}
.ClientModal_Column___eJRS textarea {
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  outline: 0;
  width: 100%;
  padding: .78571429em 1em;
}
.ClientModal_Actions__8C5-R {
  margin-top: 66px;
  position: relative;
  width: 100%;
  text-align: center;
  padding-bottom: 60px;
}
.ClientModal_UpdateButton__1-pEI {
  width: 290px;
  margin: 0 auto !important;
  border: none;
  background: #00B0E7 !important;
  border-radius: 0 !important;
  color: #fff !important;
  height: 40px;
}

.clients_Clients__1e3wD {
  display: flex;
  flex-direction: column;
}
.clients_Search__pYZZV {
  padding: 20px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-size: 18px;
  color: #3F5F7A;
}
.clients_Query__kZu1Z {
  width: 400px;
}
.clients_Headers__p7dBP, .clients_ListContainer__1aQlL {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
}
.clients_Headers__p7dBP {
  display: grid;
  grid-template-columns: 180px 60px 200px 1fr 250px 1fr 1fr 70px 80px;
  height: 60px;
  padding: 0 10px;
  align-items: center;
  font-size: 18px;
  background: linear-gradient(180deg, #00B0E7 17.19%, #0098C7 100%);
  color: #ffffff;
  font-weight: 600;
}
.clients_ListContainer__1aQlL {
  box-shadow: none;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 40px;
}

.ClientMapMarker_ClientMapMarker__1ycu- {
  height: 1px;
  position: relative;
  left: 0;
  top: 0;
  width: 1px;
  --background-color: green;
  --border-box-shadow: none;
  --border-color: blue;
  --border-opacity: 1.0;
  --border-size: calc(var(--size) + 2 * var(--border-width));
  --border-width: 4px;
  --box-shadow: none;
  --color: red;
  --font-size: 12px;
  --size: 26px;
}
.ClientMapMarker_ClientMapMarker__1ycu-.ClientMapMarker_IsSelected__1rkdJ {
  z-index: 1;
}
.ClientMapMarker_ClientMapMarker__1ycu-:hover {
  cursor: pointer;
}
.ClientMapMarker_ClientMapMarker__1ycu-:hover,
.ClientMapMarker_ClientMapMarker__1ycu-.ClientMapMarker_FocusTrigger__xyY5g.ClientMapMarker_IsFocused___6ahR {
  -webkit-filter: brightness(115%);
          filter: brightness(115%);
  left: 1px; /* push marker visually down by offseting position by 1px */
  top: 1px;
  z-index: 1000;
}
.ClientMapMarker_ClientMapMarker__1ycu-::before { /* A separate (virtual) element is used to allow setting a border opacity */
  border: var(--border-width) solid var(--border-color);
  border-radius: 100%;
  box-shadow: var(--border-box-shadow);
  content: '';
  height: var(--border-size);
  left: calc(-1 * var(--border-size) / 2);
  opacity: var(--border-opacity);
  position: absolute;
  top: calc(-1 * var(--border-size) / 2);
  width: var(--border-size);
}
.ClientMapMarker_ClientMapMarker__1ycu- > .ClientMapMarker_Label__1AXJS {
  align-items: center;
  background-color: var(--background-color);
  border-radius: 100%;
  box-shadow: var(--box-shadow);
  color: var(--color);
  display: flex;
  font-size: var(--font-size);
  font-weight: bold;
  height: var(--size);
  justify-content: center;
  left: calc(-1 * var(--size) / 2);
  opacity: 0.8;
  position: absolute;
  top: calc(-1 * var(--size) / 2);
  width: var(--size);
}
.ClientMapMarker_ClientMapMarker__1ycu-:hover > .ClientMapMarker_Label__1AXJS {
  opacity: 1;
}
.ClientMapMarker_ClientMapMarker__1ycu-.ClientMapMarker_IsSelected__1rkdJ .ClientMapMarker_Label__1AXJS {
  background: #3F5F7A;
  color: #ffffff;
  opacity: 1;
}
.ClientMapMarker_ClientMapMarker__1ycu- > .ClientMapMarker_Hint__3prks {
  bottom: calc(var(--size) / 2 - 10px);
  cursor: initial; /* The marker itself has a finger pointer which we don't want on the hint per default */
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  visibility: hidden;
  z-index: -1;
}
.ClientMapMarker_ClientMapMarker__1ycu-.ClientMapMarker_FocusTrigger__xyY5g.ClientMapMarker_IsFocused___6ahR > .ClientMapMarker_Hint__3prks,
.ClientMapMarker_ClientMapMarker__1ycu-.ClientMapMarker_HoverTrigger__3GnpF:hover > .ClientMapMarker_Hint__3prks {
  visibility: inherit;
}
.ClientMapMarker_ClientDetails__QwbAa {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid blue;
  border-radius: 20px;
  height: 100px;
  padding: 5px;
  width: 200px;
}
.ClientMapMarker_ClientDetails__QwbAa .ClientMapMarker_Name__114bt,
.ClientMapMarker_ClientDetails__QwbAa .ClientMapMarker_Address__1V4Vt {
  margin: 0;
}
.ClientMapMarker_ClientDetails__QwbAa .ClientMapMarker_Name__114bt {
  font-size: 12pt;
  font-weight: bold;
  margin-bottom: 5px;
}
.ClientMapMarker_ClientDetails__QwbAa .ClientMapMarker_Address__1V4Vt {
  font-size: 10pt;
}

.MapMarker_MapMarker__35r6c {
  position: relative;
  width: 0;
  height: 0;
}
.MapMarker_MapMarker__35r6c > img {
  position: absolute;
  width: 50px;
  height: 42px;
  left: -12.5px;
  top: -40px;
  z-index: 1; /* show marker image above hint */
}
.MapMarker_MapMarker__35r6c .MapMarker_Hint__1C2X_ {
  bottom: 30px;
  left: -120px;
  position: absolute;
  visibility: hidden;
  width: 240px;
}
.MapMarker_MapMarker__35r6c:hover > .MapMarker_Hint__1C2X_ {
  visibility: inherit;
}
.MapMarker_MapMarker__35r6c .MapMarker_Hint__1C2X_ .MuiCardHeader-title {
  font-size: 14px;
}


.SearchBar {
  padding: 20px;
}
.SearchBar > input {
  width: 100%;
  padding: 5px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.95);
  color: #808080;
  border: 1px solid #c0c0c0;
  box-shadow: 0 3px 5px 3px rgba(127, 127, 127, 0.35);
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out,
    border 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}
.SearchBar:hover > input,
.SearchBar > input:focus {
  background-color: white;
  color: #404040;
  border: 1px solid #a0a0a0;
  box-shadow: 0 3px 5px 3px rgba(127, 127, 127, 0.75);
}
.SearchBar > .Results {
  position: relative; /* Fixes input's box-shadow being above results */
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  background-color: white;
  border: 1px solid #a0a0a0;
  box-shadow: 0 3px 5px 3px rgba(127, 127, 127, 0.75);
}
.SearchBar:hover > .Results {
  display: inherit;
  opacity: 1;
}
.SearchBar > .Results > * {
  padding: 5px;
}
.SearchBar > .Results > .Item {
  cursor: pointer;
}
.SearchBar > .Results > .Item:hover {
  background-color: #f0f0f0;
}
.SearchBar > .Results > .NoMatch {
  cursor: inherit;
}

.clients-map_ClientMapHome__GpV2v {
  position: relative;
}
.clients-map_SearchBar__2kWDJ {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translate(-50%, 0);
  width: 80%;
  max-width: 500px;
}
.clients-map_ClientMapMarker__3v1fL {
  --border-width: 4px;
  --font-size: 14px;
  --size: 28px;
}

.ClientMatchingForm {
    padding: 40px;
}
.ClientMatchingForm form {
    overflow: hidden;
    padding: 20px 0px;
}
.ClientMatchingForm form button {
    float: right;
    width: 130px;
}

.TextLink:hover {
  text-decoration: underline;
}

.ClosingSection .Message {
    position: relative;
    text-align: left;
    display: flex;
    border-left: 0;
    padding: 0;
}
.ClosingSection .Message p {
    margin-bottom: 0;
}
.ClosingSection .Message i {
    color: #47D9BF;
    font-size: 22px;
}
.ClosingSection .Message .Metadata {
    color: #7b7f7f;
    font-size: 12px;
    font-weight: 100;
}
.ClosingSection .Actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.ClosingSection .Actions p {
    color: #F16A7A;
}

.PersonAvatar {
  background: #7b7f7f;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  border-radius: 13px;
}
.PersonAvatar .user.big.icon {
  color: #ffffff;
  font-size: 1.7em;
  margin-left: 4px;
}
.PersonAvatar > div {
  background: #7b7f7f;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  border-radius: 13px;
}

.Subtask {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.Subtask > .SubtaskDescription {
  display: flex;
}
.Subtask > .SubtaskDescription .PersonAvatar {
  margin-right: 10px;
}
.Subtask > .SubtaskDescription .Content .Metadata {
  color: #7b7f7f;
  font-size: 12px;
  font-weight: 100;
  display: flex;
  justify-content: space-between;
}
.Subtask > .SubtaskDescription .Content .Metadata p {
  display: inherit;
}
.Subtask > .SubtaskDescription  .Metadata p span {
  border-bottom: 1px dashed #ccc;
  padding: 0 3px;
}
.Subtask .Actions {
  display: flex;
  align-items: center;
  align-self: center;
  margin-bottom: 0px;
  padding-right: 5px;
}
.Subtask .Actions > * {
  cursor: pointer;
}
.Subtask .Actions .button {
  width: 40px;
  padding: 0px;
  margin-right: 0px;
  box-shadow: none !important;
}
.Subtask .Actions .Duedate {
  width: 40px;
}
.Subtask .Actions .disabled {
  opacity: 0.2;
  cursor: unset;
}

.ShowTaskModal > .header,
.ShowTaskModal > .content,
.ShowTaskModal > .actions {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.ShowTaskModal .header .Metadata {
  display: flex;
  justify-content: space-between;
}
.ShowTaskModal .header .Metadata .Title {
  display: flex;
  flex-direction: column;
}
.ShowTaskModal .header .Metadata .Title h3 {
  font-size: 22px;
  font-weight: 300;
  margin: calc(2rem - .14285714em) 0 1rem;
}
.ShowTaskModal .header .Metadata .Title .ui.mini.image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.ShowTaskModal .header .Metadata .Title .Client {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.ShowTaskModal .header .Metadata .ImportantTag {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 5px;
}
.ShowTaskModal .header .Metadata button.ui.basic {
  border-radius: 20px !important;
  font-size: 12px;
}
.ShowTaskModal .header .Metadata button.ui.basic.important {
  background: #f4827c !important;
  color: #ffffff !important;
}
.ShowTaskModal .TaskDescription {
  display: flex;
  flex-direction: column;
}
.ShowTaskModal .TaskDescription .Content {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 16px;
}
.ShowTaskModal .TaskDescription .Metadata {
  color: #7b7f7f;
  font-size: 12px;
  font-weight: 100;
}
.ShowTaskModal .Subtasks {
  margin-bottom: 20px;
  margin-right: -5px;
  max-height: 350px;
  overflow-y: scroll;
  padding-bottom: 10px;
}
.ShowTaskModal textarea {
  overflow: auto;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 7px;
  outline: 0;
}
.ShowTaskModal .CommentArea {
  display: flex;
  margin-bottom: 10px;
}
.ShowTaskModal .icon {
  color: #7b7f7f;
}
.ShowTaskModal .CommentArea .PersonAvatar {
  margin-right: 10px;
}
.ShowTaskModal .CommentArea .Actions {
  display: flex;
  cursor: pointer;
}
.ShowTaskModal .CommentArea .Actions .Duedate {
  margin: 0px 10px;
}
.ShowTaskModal .CommentArea .Actions .Duedate p {
  margin-top: 4px;
}
.ShowTaskModal .TaskClosedArea .Content {
  position: relative;
  text-align: left;
  display: flex;
}
.ShowTaskModal .TaskClosedArea .Content p {
  margin-bottom: 0;
}
.ShowTaskModal .TaskClosedArea .Content i {
  color: #47D9BF;
  font-size: 22px;
}
.ShowTaskModal .TaskClosedArea .Content .Metadata {
  color: #7b7f7f;
  font-size: 12px;
  font-weight: 100;
}
.ShowTaskModal .TaskClosedArea .Actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.ShowTaskModal .TaskClosedArea .Actions p {
  color: #F16A7A;
}

.FloatingMenuItem {
    position: fixed;
    right: 33px;
    bottom: 85px;
    text-align: right;
    z-index: 5;
}
.FloatingMenuItem .InputMenuItem {
    margin-bottom: 10px;
    cursor: pointer;
}
.FloatingMenuItem .InputMenuItem button {
    background: #0D6FBA;
    color: #FFFFFF;
    font-size: 16px;
    padding: 8px !important;
    margin: 0;
}
.FloatingMenuItem .InputMenuItem button:hover {
    color: #FFFFFF;
}
.FloatingMenuItem .InputMenuItem.Gesundheitszustand button {
    background: #00B0E7;
}
.FloatingMenuItem .InputMenuItem.General button {
    background: #7B7F7F;
}
.FloatingMenuItem .InputMenuItem.Tourenplanung button:hover {
    background: #0D6FBA;
}
.FloatingMenuItem .InputMenuItem .label{
    display: inline-block;
    margin-right: 8px;
    padding: 4px 10px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 1px 1px 1px #cfcfcf;
    transition: opacity 250ms ease-in-out;
}
.FloatingMenuItem .InputMenuItem img {
    width: 32px;
    padding: 6px;
    display: inline-block;
    vertical-align: middle;
    background: #47D9BF;
    border-radius: 50%;
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    text-align: center;
    color: #ffffff !important;
}
.CreateMessageModal .header .Top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.CreateMessageModal .header button.ui.basic {
    border-radius: 20px !important;
}
.CreateMessageModal .header button.ui.basic.important {
    background: #f4827c !important;
    color: #ffffff !important;
}
.CreateMessageModal .Client span {
    color: #888;
    font-size: 14px;
}
.CreateMessageModal .Client .ui.mini.image {
    width: 25px;
}
.CreateMessageModal .content {
    padding: 3.5rem 1.5rem !important;
}
.CreateMessageModal textarea {
    overflow: auto;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 7px;
    outline: 0;
}
.ModalContent {
    border-bottom: 1px solid rgb(224, 224, 224) !important;
    border-top: 1px solid rgb(224, 224, 224) !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}
.ModalContent .HeaderInput {
    position: absolute;
    top: 0px;
    right: 0px;
}

.FloatingAddButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 5;
}
.FloatingAddButton .ui.circular.button {
    background: #00B0E7 !important;
    font-size: 20px;
    color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px;
}
.ClientInfo .ProfileAndStatus{
    text-align: center;
}
.ClientInfo .ProfileAndStatus h3 {
    margin-top: 5px;
    margin-bottom: 10px;
}
.ClientInfo .ProfileAndStatus .AvatarAndStatus{
    position: relative;
}
.ClientInfo .ProfileAndStatus h4{
   padding: 10px 0px 30px 0px;
}
.ClientInfo .ProfileAndStatus span {
    background: #7B7F7F;
    width: 25px;
    border-radius: 16px;
    color: #fff;
    position: absolute;
    bottom: 9px;
    right: 32%;
    height: 25px;
}
.ClientInfo .ProfileAndStatus span.active {
    background: #47D9BF;
}
.ClientInfo .ProfileAndStatus a.tourplanning {
    margin: 0px 0px 16px;
    display: block;
    text-align: center;
    font-size: 16px;
}
.ClientInfo .PatientGeneralInfo ul > li {
    list-style: none;
    display: flex;
    overflow: hidden;
    margin: 0 0 10px 0;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 10px;
}
.ClientInfo .PatientGeneralInfo ul > li > label {
    width: 40%;
}
.ClientInfo .PatientGeneralInfo ul > li span {
    width: 60%;
}
.ClientInfo .PatientGeneralInfo ul > li span dd label {
    opacity: 0.8;
}
.ClientInfo .PatientGeneralInfo ul > li > ul {
    display: block;
    position: relative;
    width: 60%;
}
.ClientInfo .PatientGeneralInfo ul > li > ul > li {
    display: block;
    margin-bottom: 5px;
}
.ClientInfo .PatientGeneralInfo ul > li > ul > li:last-child {
    border-bottom: none;
}
.ClientInfo .PatientGeneralInfo ul > li > ul > li dl {
    padding-bottom: 5px;
}
.ClientInfo .PatientGeneralInfo ul > li > ul > li dl dt {
    font-weight: bold;
    margin-bottom: 5px;
}

.DesiredTimeWindows_Header__2wxLN {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-settings_ClientSettings__1Cf1H {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px 250px auto;
  grid-gap: 20px;
  padding: 20px 10px;
}
.client-settings_UnwantedCaregivers__2u4Hr {
  width: 100%;
}
.client-settings_UnwantedCaregiversHeader__1-mBK {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 900;
  padding-left: 10px;
  margin-bottom: 0;
}
.client-settings_UnwantedCaregiversList__TXGj0 {
  overflow-y: auto;
  height: calc(100% - 40px);
  border: 1px solid rgba(34,36,38,.1);
  background: white;
}
.client-settings_UnwantedCaregiver__ZaIzr {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(34,36,38,.1);
  padding: 0px 10px;
}
.client-settings_DesiredTimeWindows__JZnfI {
  grid-column: span 2;
}

.TaskDescription {
  display: flex;
  flex-direction: column;
}
.TaskDescription .Title {
  display: flex;
}
.TaskDescription .Title h4 {
  margin-bottom: 5px;
}
.TaskDescription .Title .label {
  position: relative;
  bottom: 8px;
  left: 3px;
  background: #00B0E7;
  color: white;
  font-size: 12px;
  padding: 5px 7px;
}
.TaskDescription .Title .label.wichtig {
  background: #f4827c;
}
.TaskDescription .Content {
  margin-bottom: 7px;
  line-height: 1.6285em;
  word-wrap: break-word;
}
.TaskDescription .Content .ClientLink::after {
  content: ' - ';
}
.TaskDescription .Metadata {
  color: #7b7f7f;
  font-size: 12px;
}
.TaskDescription .Metadata p span {
  border-bottom: 1px dashed #ccc;
}
.TaskMetadata {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.TaskMetadata .Status {
  color: #00B0E7;
}
.TaskMetadata .Assignment {
  display: flex;
  justify-content: center;
  align-items: center;
}
.TaskMetadata .Assignment .SmallAvatar {
  margin-left: -7px;
  z-index: 2;
}
.TaskMetadata .Assignment .SmallAvatar.FormSubtask {
  z-index: 1;
  position: relative;
}
.TaskMetadata .Assignment .DueDate {
  margin: 0 5px 0px 0px;
  padding-right: 5px;
}
.TaskMetadata .Status {
  font-size: 12px;
}


.CategoryIcon {
    position: absolute;
    bottom: 5px;
    right: 3px;
    border-radius: 12px;
    height: 24px;
    width: 24px;
    background: #7B7F7F;
    padding: 3px;
    color: #ffffff !important;
}
.CategoryIcon.health {
    background: #00B0E7;
}
.CategoryIcon.visit-planning {
    background: #0D6FBA;
}
.CategoryIcon.drug-order {
    background: #47D9BF;
}
.TaskAvatar {
    display: flex;
    align-items:  center;
    justify-content:  center;
    position: relative;
}
.TaskAvatar .InnerAvatar {
    position: relative;
}


.Message {
    border-left: 3px solid #7B7F7F;
    display: grid;
    grid-template-columns: 65px auto 80px;
    grid-template-areas: "Avatar TaskDescription TaskMetadata";
    grid-column-gap: 5px;
    padding: 10px;
    cursor: pointer;
}
.Message.new, .Message.opened {
    background: #f0f8ff;
}
.Message.health {
    border-left: 3px solid #00B0E7;
}
.Message.visit-planning {
    border-left: 3px solid #0D6FBA;
}
.Message.drug-order {
    border-left: 3px solid #47D9BF;
}
.Message:hover {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    background: #f0f0f0;
}
.Message .Avatar {
    display: flex;
    align-items:  center;
    justify-content:  center;
    position: relative;
}
.Message .Avatar .InnerAvatar {
    position: relative;
}
.Messages {
    background: white;
    height: 100%;
}
.Messages .ui.divider {
    margin: 0;
}
.Messages .Subheader {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    padding: 7px;
    height: 40px;
}
.Messages .Subheader > p {
    margin: 0;
    font-size: 18px;
}
.Messages .Subheader .Filter.menu {
    height: 296px;
    width: 250px;
}
.Messages .Subheader .item.active {
    font-weight: 900;
}
.Messages .Subheader .menu > .header {
    font-size: unset;
}
.Messages .Subheader .UserDropdown {
    width: 100%;
    border-radius: 0% !important;
}
.Messages .Subheader .UserDropdown .visible.menu.transition {
    position: absolute;
    top: 36px !important;
    left: 6px;
    height: 150px;
    box-shadow: none;
    border-radius: 0% !important;
}
.Messages .Subheader .SortOptions {
    display: flex;
    position: absolute;
    right: 5px;
}
.Messages .Subheader .SortOptions > .icon {
    margin-top: 3px;
    margin-right: 0px;
    cursor: pointer;
}
.Messages .Subheader .SortOptions .ui.button.active {
    background-color: #4AAFE2;
}
.Messages .MessageContainer {
    height: 100%;
    overflow-y: scroll;
}
.Messages .MessageContainer .MessageList {
    height: calc(100% - 40px);
}

.ClientMessages > .MainArea {
  height: 100%;
}
.ClientMessages > .MainArea .FilterButtons {
  padding: 0px 0px 10px 0px;
}
.ClientMessages  > .MainArea  > .FilterButtons > button {
  border: none;
  padding: 5px;
  background: transparent;
  color: #0D6FBA;
  opacity: 0.6;
  outline: 0;
  cursor: pointer;
}
.ClientMessages  > .MainArea  > .FilterButtons > button.active {
  font-weight: bold;
  opacity: 1;
}

.ClientPage {
    display: grid;
    grid-template-columns: 300px auto;
    grid-template-rows: 100px calc(100vh - 152px);
    grid-template-areas:
      "header header"
      "leftSidebar mainArea";
    grid-column-gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}
.ClientPage > .MainArea {
    grid-area: mainArea;
    background: #fafafa;
    height: 100%;
    display: grid;
    grid-template-rows: 36px calc(100% - 36px);
}
.ClientPage > .MainArea .FilterButtons {
    padding: 7px 0px 10px 0px;
}
.ClientPage > .LeftSidebar {
    background: #fff;
    border: 1px solid #fcfcfc;
    padding: 20px 15px;
    grid-area: leftSidebar;
    height: 100%;
    overflow-y: scroll;
}
.ClientPage > .Header {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.ClientPage > .Header h3 {
    color: #0D6FBA;
    margin-bottom: 5px;
}
.ClientPage  > .MainArea  > .FilterButtons > button {
    border: none;
    padding: 5px;
    background: transparent;
    color: #0D6FBA;
    opacity: 0.6;
    outline: 0;
    cursor: pointer;
}
.ClientPage  > .MainArea  > .FilterButtons > button.active {
    font-weight: bold;
    opacity: 1;
}

.ClientVisits {
  max-width: 1200px;
  margin: 25px auto 35px auto;
  display: flex;
  flex-direction: column;
}
.ClientVisits > .Header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0px 20px;
}
.ClientVisits > .Header h3 {
  color: #0D6FBA;
  margin-bottom: 5px;
}
.ClientVisitsContainer {
  flex-grow: 1;
  overflow-y: scroll;
  height: 0;
}
.ClientVisits .ClientVisitsContainer > ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  border: 0.5px solid rgba(34, 36, 38, .15);
}
.ClientVisits .ClientVisitsContainer > ul > li {
  border: 0.5px solid rgba(34, 36, 38, .15);
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
}
.ClientVisits .ClientVisitsContainer > ul > li > h4 {
  border-bottom: 1px solid rgba(34, 36, 38, .15);
  padding: 1em;
  margin-bottom: 0px;
}
.ClientVisits .ClientVisitsContainer > ul > li > ul {
  border-bottom: 1px solid rgba(34, 36, 38, .15);
  list-style: none;
  padding: 5px;
}
.ClientVisits .ClientVisitsContainer > ul > li > ul > li {
  text-align: left;
}
.ClientVisits .ClientVisitsContainer .Services {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
}
.ClientVisits .Services.actual,
.ClientVisits .Legend li.actual {
  background-color: #00b33c;
}
.ClientVisits .Services.planned,
.ClientVisits .Legend li.planned {
  background-color: #cca300;
}
.ClientVisits .Services.planned-unassigned,
.ClientVisits .Legend li.planned-unassigned {
  background-color: #c0c0c0;
}
.ClientVisits .Services li:nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1;
  padding-right: 5px;
}
.ClientVisits .Services li:nth-child(2) {
  grid-column: 2 / 2;
  grid-row: 1;
  text-align: right;
}
.ClientVisits .Services li:nth-child(3) {
  grid-column: 1 / 3;
  grid-row: 2;
}
.ClientVisits .Services li:nth-child(4) {
  grid-column: 1 / 3;
  grid-row: 3;
  padding-top: 7px;
}
.ClientVisits .Legend {
  display: block;
  max-width: 200px;
  margin-top: 10px;
}
.ClientVisits .Legend li {
  align-items: center;
  border-bottom: 1px solid rgba(34, 36, 38, .15);
  display: flex;
  height: 40px;
  list-style: none;
  padding-left: 0.5em;
}
.NoVisits {
  padding: 30px 0px;
  text-align: center;
}

.Forbidden_Forbidden__1VcBm {
  max-width: 90%;
  display: block;
  position: relative;
  margin: 50px auto;
}
.Forbidden_Message__zqg_S {
  max-width: 1000px;
  margin: 0 auto !important;
}

.VisitsOfTodayApp {
  display: flex;
  flex-direction: column;
}
.ToursContainer {
  flex-grow: 1;
  height: 0;
  overflow-y: scroll;
}
.VisitsOfTodayApp > .ToursContainer > .Tours {
  width: 100%;
}
.VisitsOfTodayApp > .ToursContainer > .Tours > .accordion.ui.styled {
  width: 100%;
}
.VisitsOfTodayApp > .ToursContainer > .Tours > .accordion > .content.active {
  padding-bottom: 0;
}
.VisitsOfTodayApp > .ToursContainer > .Tours > .accordion > .content > table > tbody > tr.open {
  background-color: #ee9090;
}
.VisitsOfTodayApp > .ToursContainer > .Tours > .accordion > .content > table > tbody > tr.inProgress {
  background-color: #eeee90;
}
.VisitsOfTodayApp > .ToursContainer > .Tours > .accordion > .content > table > tbody > tr.done {
  background-color: #90ee90;
}

.LoginScreen_LoginScreen__2Gwii {
  background: linear-gradient(90deg,#3f5f7a 60%,#00b0e7 0);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.LoginScreen_LoginForm__2Zxos {
  display:flex;
  margin:auto;
  width: 1000px;
  height: 524px;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0px 30px 45px rgba(0, 0, 0, 0.2);
}
.LoginScreen_MarketingContainer__2aRGP {
  display:flex;
  flex-direction: column;
  min-width:466px;
  height: 524px;
  border-radius: 4px 0px 0px 4px;
  background-image: url(/static/media/login-page-bg.86d9e7b4.png);
  background-size: cover;
}
.LoginScreen_TaleaLogo__1K8wz {
  width: 111px;
  margin:15px 0 0 26px;
}
.LoginScreen_MarketingText__obSqj {
  margin-top: 265px;
}
.LoginScreen_MarketingText__obSqj > h2 {
  color: #FFFFFF;
  text-align:center;
  font-size: 40px;
  font-weight: 500;
  padding:0;
  margin:0;
}
.LoginScreen_MarketingText__obSqj > p {
  color: #FFFFFF;
  text-align:center;
  font-size: 25px;
  padding: 7px 40px;
  margin:0;
}
.LoginScreen_LoginContainer__39uGS {
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width:100%;
  height: 524px;
}
.LoginScreen_LoginWrapper__1sTTj {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:361px;
}
.LoginScreen_LoginWrapper__1sTTj > h3 {
  color: #3F5F7A;
  text-align:center;
  font-size: 30px;
  font-weight: 600;
  line-height: 42px;
  padding:0;
  margin:0;
}
.LoginScreen_LoginButton__2Mhox {
  background: #00B0E7;
  border: 1px solid #0098C7;
  max-width: 290px !important;
  border-radius: 2px !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  text-align: center;
  color: #FFFFFF;
  margin-top: 45px !important;
}

.LogoutScreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.LogoutScreen > .LogoutForm {
  width: 450px;
}

.LogoutScreen > .LogoutForm > .header {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px;
}

.LogoutScreen > .LogoutForm > .header > span {
  font-size: 26px;
  color:  #00B0E7;
}

.LogoutScreen > .LogoutForm > .header > .image {
  width: 150px;
}

.MonthSelector {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.MonthSelector > .SelectedMonth {
  width: 110px;
  text-align: center;
}
.MonthSelector > .button {
  margin: 0;
}

.DayCell_DayCell__3c9xB {
  cursor: pointer;
  width: 100%;
  height: 34px;
  display: flex;
  flex: 0 0 40px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-right: 2px solid #F6F6F6;
  background-color: #DEE7EE;
  color: #FFFFFF;
}
.DayCell_DayCell__3c9xB.DayCell_isWeekend__3zGpA {
  background-color: #DEE7EE;
}
.DayCell_DayCell__3c9xB.DayCell_isEvenRow__nthE6 {
}
.DayCell_DayCell__3c9xB.DayCell_isEvenRow__nthE6.DayCell_isWeekend__3zGpA {
  background-color: #E6ECF1;
}
.DayCell_DayCell__3c9xB:hover, .DayCell_DayCell__3c9xB.DayCell_isEvenRow__nthE6.DayCell_isWeekend__3zGpA:hover {
  background-color: #B8C5D0;
}
.DayCell_DayCellPopup__2XNYc {
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.25) !important;
}
.DayCell_PopupContent__1OJbQ {
  display: flex;
  max-width: 135px;
  flex-wrap: wrap;
  cursor: pointer;
}
.DayCell_ShiftKind__3QLeX {
  width: 35px;
  height: 35px;
  border: 2px solid #F6F6F6;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  position: relative;
}
.DayCell_ShiftKind__3QLeX:hover {
  transform: scale(1.2);
  transition: ease-in-out transform 300ms;
}

.PlannedTourDropdown_PlannedTourDropdown__VVA0r {
  min-width: 8em !important;
}
.PlannedTourDropdown_PlannedTourDropdown__VVA0r > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.shift-plan_GridRow__2nXGm {
  position: relative;
  display: flex;
  flex-direction: row;
}
.shift-plan_GridColumn__3cPTj {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.shift-plan_MonthDaysContainer__2dtOj {
  display: flex;
  flex-direction: row;
  flex: 0 0 75px;
  z-index: 10;
  color: #3F5F7A;
  background-color: #FFFFFF;
}
.shift-plan_CaregiverList__3vxBM {
  overflow: hidden !important;
  outline: none;
}
.shift-plan_HeaderGrid__kiSgu {
  width: 100%;
  overflow: hidden !important;
  outline: none;
}
.shift-plan_Days__23HOp {
  width: 100%;
  outline: none;
}
.shift-plan_Title__2sgBr,
.shift-plan_SearchField__2FXsi,
.shift-plan_Caregiver__3VA00 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  border: 1px solid #F6F6F6;
}
.shift-plan_Caregiver__3VA00 {
  color: #ffffff;
}
.shift-plan_QualifiedCaregiver__3LvkR {
  background: #995FA3;
}
.shift-plan_NonQualifiedCaregiver__15jiP {
  background: #5F62A3;
}
.shift-plan_Caregiver__3VA00.shift-plan_isEvenRow__3ovyS {
  background-color: #FCFCFC;
}
.shift-plan_QualificationGroup__yiiNd {
  position: absolute;
  left: 3px;
  top: 0px;
  font-size: 8px;
}
.shift-plan_CaregiverName__255RF {
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shift-plan_SearchField__2FXsi input {
  padding: 7px 10px !important;
  border: none !important;
  border-radius: 0px !important;
}
.shift-plan_Title__2sgBr {
  background-color: #3F5F7A;
  color: #FFFFFF;
  border-top: none;
}
.shift-plan_MonthHeaderCell__3zdPK {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #3F5F7A;
  color: #FFFFFF;
  border-bottom: 1px solid #F6F6F6;
  border-right: 1px solid #F6F6F6;
  border-left: 1px solid #F6F6F6;
}
.shift-plan_MonthHeaderCell__3zdPK:first-of-type {
  border-left-width: 1px;
}
.shift-plan_DayHeaderCell__1lXMw {
  color: #111111;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  border: 1px solid #F6F6F6;
}
.shift-plan_DayHeaderCell__1lXMw.shift-plan_isWeekend__6KRwh {
  background-color: #DEE7EE;
}
.shift-plan_DayHeaderCell__1lXMw > span {
  margin-right: 10px;
}
.shift-plan_DayCellContent__25jZA {
  cursor: pointer;
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border: 1px solid #F6F6F6;
  background-color: #FFFFFF;
  color: #000066;
}
.shift-plan_DayCellContent__25jZA > .shift-plan_PlannedTourDropdown__m6ZOY {
  min-width: auto !important;
  max-width: 100%;
  width: 100%;
  height: 100%;
  min-height: 100% !important;
  line-height: 100% !important;
}

.shift-planner2_Toolbar__1jnYe {
  display: flex;
  justify-content: space-between;
  margin: 25px 3px 10px 3px;
}
.shift-planner2_CaregiverQualificationDropdown__1kVBk {
  z-index: 11 !important;
  width: 162px !important;
}

.OAuthCallback {
    display: flex;
    align-items: center;
    justify-content: center;
}

.OAuthCallback > .Error {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.OrderSteps_OrderSteps__tQTvU {
  counter-reset: step;
  width: 800px;
}
.OrderSteps_OrderSteps__tQTvU li {
  list-style-type: none;
  font-size: 18px;
  width: 25%;
  float: left;
  position: relative;
  text-align: center;
  color: #B8C5D0;
}
.OrderSteps_OrderSteps__tQTvU li:before {
  position: relative;
  width: 47px;
  height: 47px;
  content: counter(step);
  color: white;
  font-weight: 800;
  font-size: 25px;
  counter-increment: step;
  line-height: 46px;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #B8C5D0;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
  z-index: 2;
}
.OrderSteps_OrderSteps__tQTvU li:after {
  width: 100%;
  height: 10px;
  content: '';
  position: absolute;
  background-color: #B8C5D0;
  top: 22px;
  left: -50%;
  z-index: 1;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
}
.OrderSteps_OrderSteps__tQTvU li:first-child:after {
  content: none;
}
.OrderSteps_OrderSteps__tQTvU li.OrderSteps_Active__23kKL {
  color: #00B0E7;
}
.OrderSteps_OrderSteps__tQTvU li.OrderSteps_Active__23kKL:before {
  width: 52px;
  height: 52px;
  border-color: white;
  background-color: #00B0E7;
  border: 5px solid #FFFFFF;
  line-height: 40px;
}
.OrderSteps_OrderSteps__tQTvU li.OrderSteps_Active__23kKL:after {
  background-color: #00B0E7;
  border: 5px solid #FFFFFF;
  height: 20px;
  top: 16px;
}
.OrderSteps_OrderSteps__tQTvU li.OrderSteps_Active__23kKL:last-child {
  color: #00E794;
}
.OrderSteps_OrderSteps__tQTvU li.OrderSteps_Active__23kKL:last-child:before,
.OrderSteps_OrderSteps__tQTvU li.OrderSteps_Active__23kKL:last-child:after {
  background: linear-gradient(0deg, #00E794, #00E794), #00B0E7
}

.TourOrder_Order__2U5nG {
  position: relative;
  display: block;
  overflow-y: scroll;
}
.TourOrder_Header__267j1 {
  background: white;
  height: 50px;
}
.TourOrder_HeaderContainer__1EZTa {
  display: flex;
  align-items: center;
  max-width: 90% !important;
  margin: 0px auto;
  height: 100%;
}
.TourOrder_Breadcrumb__1qPuV {
  font-size: 16px !important;
  color: #3F5F7A !important;
}
.TourOrder_OrderStepsContainer__2srdW {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TourOrder_TourOrder__1fEhq {
  max-width: 90%;
  margin: 40px auto;
}
.TourOrder_TourOrder__1fEhq:first-child {
  margin-top: 0em;
}
.TourOrder_TourOrder__1fEhq:last-child {
  margin-bottom: 0em;
}
.TourOrder_TourOrder__1fEhq > li {
  transition: background 0.1s ease, color 0.1s ease;
  padding: 5px 0px;
}
.TourOrder_TourOrder__1fEhq > li:nth-child(2n) {
  background-color: rgba(0, 0, 50, 0.02);
}
.TourOrder_OrderContainer__1MaTz {
  background: #fff;
  font-size: 16px;
  max-width: 700px;
  margin: 45px auto;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}
.TourOrder_ServiceAbbreviation__-daNB {
  font-weight: bold;
}
.TourOrder_WhileCollapsed__38-Lo:hover {
  cursor: pointer;
  background: #f6f6f6;
}
.TourOrder_WhileCollapsed__38-Lo{
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.TourOrder_WhileExpanded__T-6UO {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.TourOrder_FreeTextForm__bCR4c {
  margin: 30px 0;
}
.TourOrder_OrderInfo__3j2OU {
  font-size: 18px !important;
}
.TourOrder_Actions__1zjUw {
  text-align: center;
  margin: 50px 0 30px;
  display: block;
}
.TourOrder_Actions__1zjUw > button {
  font-size: 18px !important;
}
.TourOrder_ConfirmedButton__1CTMn {
  color: white !important;
  background-color: #00E794 !important;
}
.TourOrder_CancelButton__3zpvH {
  background: #00769B !important;
  color: white !important;
}
.TourOrder_ConfirmedButton__1CTMn:hover, .TourOrder_CancelButton__3zpvH:hover {
  opacity: 0.7;
}
.StationDropdown_StationDropdown__TscyB {
  min-width: 8em !important;
}

.FileUploadForm_FormContent__2jBvH {
  margin: 40px;
  border-radius: 3px;
  text-align: center;
}
.FileUploadForm_CloudSvg__3LJko {
  width: 250px !important;
  margin: 0 auto !important;
}
.FileUploadForm_DragActive__1f52z {
  color: #3F5F7A;
  font-weight: 600;
  font-size: 28px;
  margin: 5px 0px;
  background: #ededed;
}
.FileUploadForm_UploadForm__Vd3vF {
  border: 2px dashed #999;
  position: relative;
  display: block;
  padding-top: 75px;
  margin-bottom: 10px;
}
.FileUploadForm_UploadForm__Vd3vF > h4 {
  color: #3F5F7A;
  font-weight: 600;
  font-size: 28px;
  margin: 5px 0px;
}
.FileUploadForm_UploadForm__Vd3vF > span {
  font-size: 16px;
  color: #B8C5D0;
  text-align: center;
  display: block;
}
.FileUploadForm_UploadButtonWrapper__xsvvP {
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
}
.FileUploadForm_UploadButton__8iFOE {
  background: #00B0E7;
  border: 1px solid #0098C7;
  border-radius: 2px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  _display: inline-block;
  margin: 44px auto 0 auto;
  height: 40px;
  padding: 10px;
}
.FileUploadForm_InputField__2YNyN {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.FileUploadForm_PdfInputType__cFQiJ {
  margin-top: 15px;
}

.FileUploadStatus_FormContent__1xBUA {
  margin: 40px;
  border-radius: 3px;
  text-align: center;
}
.FileUploadStatus_FileUploadStatus__328hN {
  border: 2px dashed #999;
  position: relative;
  display: block;
  padding-top: 75px;
}
.FileUploadStatus_UploadStatusCloud__3TBdh {
  width: 250px !important;
  margin: 0 auto !important;
}
.FileUploadStatus_FileUploadTitle__AUlhB {
  font-weight: 600;
  font-size: 24px;
  margin: 5px 0px;
  color: #3F5F7A;
}
.FileUploadStatus_FileUploadStatus__328hN > span {
  font-size: 16px;
  text-align: center;
  display: block;
  line-height: 24px;
  color: #B8C5D0;
}
.FileUploadStatus_Error__3okDi h4 {
  color: #ED5454 !important;
}
.FileUploadStatus_Error__3okDi > button {
  background: #ED5454 !important;
  border: 1px solid #FF826E !important;
}
.FileUploadStatus_Success__7BNan h4 {
  color: #3F5F7A;
}
.FileUploadStatus_Success__7BNan > button {
  background: #FFD47C !important;
  border: 1px solid #FFCB60 !important;
}
.FileUploadStatus_FileUploadStatusButton__1yoIF {
  width: 290px;
  border-radius: 2px;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff !important;
  display: inline-block;
  margin: 44px auto 0 auto !important;
  height: 40px;
  background: #00B0E7 !important;
  border: 1px solid #0098C7 !important;
}
.FileUploadStatus_ErrorMessages__2W5Ex {
  color: #ED5454;
  margin-top: 20px;
}

.FileUploadModal_FileUploadModal__1WEAO > section > div:first-child {
 padding: 50px 25px 57px;
}
.FileUploadModal_FormContent__c7C-F {
  margin: 40px;
  border-radius: 3px;
  text-align: center;
}
.PlannedVisit_PlannedVisit___kNqL {
  padding: 0 20px;
  color: #3F5F7A;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.PlannedVisit_PlannedVisit___kNqL:hover {
  background: white;
}
.PlannedVisit_PlannedVisit___kNqL.PlannedVisit_isExpanded__1ksCD {
  background: white;
}
.PlannedVisit_VisitDescription__2P-5h {
  display: grid;
  grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr 100px;
  align-items: center;
  height: 60px;
  cursor: pointer;
  font-size: 18px;
}
.PlannedVisit_ActionButtons__3N-Uk {
  margin: 0 !important;
}
.PlannedVisit_PlannedVisit___kNqL:hover > .PlannedVisit_ClientDescription__1bEn_ > .PlannedVisit_ActionButtons__3N-Uk {
  visibility: visible;
}
.PlannedVisit_PlannedVisit___kNqL.PlannedVisit_isCollapsed__3hb0j > .PlannedVisit_ClientDescription__1bEn_ > .PlannedVisit_ActionButtons__3N-Uk {
  transition: transform .5s ease-in-out;
}
.PlannedVisit_PlannedVisit___kNqL.PlannedVisit_isExpanded__1ksCD > .PlannedVisit_ClientDescription__1bEn_ > .PlannedVisit_ActionButtons__3N-Uk {
  visibility: visible;
  transition: transform .5s ease-in-out;
}
.PlannedVisit_PlannedVisit___kNqL.PlannedVisit_isExpanded__1ksCD > .PlannedVisit_VisitDetails__1KJay {
  width: 100%;
  height: 100%;
  display: flex;
  grid-column: span 6;
  justify-content: space-between;
  animation: PlannedVisit_slide-bottom__HqJni 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.PlannedVisit_PlannedVisit___kNqL.PlannedVisit_isCollapsed__3hb0j > .PlannedVisit_VisitDetails__1KJay {
  display: none;
  animation: PlannedVisit_slide-top__14uXt 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.PlannedVisit_Services__2bINl, .PlannedVisit_VisitInfo__3FVTR {
  width: 48%;
  margin: 20px 0px;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.15);
}
.PlannedVisit_ServicesHeader__2Resf, .PlannedVisit_VisitInfoHeader__1GxfH {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #00B0E7 17.19%, #0098C7 100%);
  padding-left: 20px;
  height: 40px;
  color: white;
  font-size: 18px;
}
.PlannedVisit_ServicesList__3jVm- {
  padding: 5px 15px;
}
.PlannedVisit_Service__1wbOm {
  height: 40px;
  background: #3F5F7A;
  border-radius: 2px;
  color: white;
  display: inline-block;
  padding: 10px;
  margin: 5px;
}
.PlannedVisit_VisitInfoText__9UHjU {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 20px;
  border: none;
  font-size: 14px;
  color: #3F5F7A;
}
.PlannedVisit_VisitInfoText__9UHjU::-webkit-input-placeholder {
  color: #C9C9C9;
}
/**
* ----------------------------------------
* animation slide-bottom
* ----------------------------------------
*/
@keyframes PlannedVisit_slide-top__14uXt {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(30px);
  }
}

.PlannedVisits_PlannedVisits__1ShRB {
  max-width: 90%;
  margin: 40px auto;
}
.PlannedVisits_PlannedVisits__1ShRB.PlannedVisits_isCancelled__3HFZ2 {
  opacity: 0.3;
}
.PlannedVisits_PlannedVisitsHeader__1cZL3 {
  width: 100%;
  height: 60px;
  margin-top: 40px;
  display: grid;
  grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr 100px;
  align-items: center;
  padding: 0 20px;
  background: linear-gradient(180deg, #00B0E7 17.19%, #0098C7 100%);
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
}
.PlannedVisits_PlannedVisits__1ShRB:first-child {
  margin-top: 0em;
}
.PlannedVisits_PlannedVisits__1ShRB:last-child {
  margin-bottom: 0em;
}
.PlannedVisits_PlannedVisits__1ShRB > li {
  transition: background 0.1s ease, color 0.1s ease;
  padding: 5px 0px;
}
.PlannedVisits_PlannedVisits__1ShRB > li:nth-child(2n) {
  background-color: rgba(0, 0, 50, 0.02);
}
.PlannedVisits_ServiceAbbreviation__y_8Ak {
  font-weight: bold;
}
.PlannedVisits_WhileCollapsed__1aCe9:hover {
  cursor: pointer;
  background: #f6f6f6;
}
.PlannedVisits_WhileCollapsed__1aCe9{
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.PlannedVisits_WhileExpanded__2fOuW {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.PlannedVisits_FreeTextForm__1_Q7h {
  margin: 30px 0;
}
.PlannedVisits_OrderInfo__2fJky {
  font-size: 18px !important;
}
.PlannedVisits_Actions__1bIlI {
  text-align: center;
  margin: 50px 0 30px;
  display: block;
}
.PlannedVisits_Actions__1bIlI > button {
  font-size: 18px !important;
}
.PlannedVisits_ConfirmedButton__2_hSA {
  color: white !important;
  background-color: #00E794 !important;
}
.PlannedVisits_CancelButton__ZgBFM {
  margin-right: 20px !important;
  background: #00769B !important;
  color: white !important;
}
.PlannedVisits_ConfirmedButton__2_hSA:hover, .PlannedVisits_CancelButton__ZgBFM:hover {
  opacity: 0.7;
}

.SelectButton_LinearProgress__1xqqZ {
  width: 100%;
}

.StationSelect_StationSelect__37meh::before {
  opacity: 0; /* hides underline as long as station picker is not focused */
}
.StationSelect_StationSelect__37meh > .MuiSelect-select:focus {
  background-color: transparent;
}
.StationSelect_StationSelect__37meh > .MuiSelect-icon {
  opacity: 0.3;
  transition: opacity 0.1s;
}
.StationSelect_StationSelect__37meh:hover > .MuiSelect-icon,
.StationSelect_StationSelect__37meh.Mui-focused > .MuiSelect-icon {
  opacity: 1;
}


.PlannedTourEditDialog_WeekdayLabel__1Mp_x {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 10px !important;
}

.PlannedTourAssignment_PlannedTourAssignment__1WZOF {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.PlannedTourAssignment_PlannedTourAssignment__1WZOF p {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 0;
}
.RecoEngine {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 52px);
}

.RecoEngine > .form {
  display: flex;
}

.RecoEngine > .form > .field {
  margin: 0px 10px 0px 0px;
}

.RecoEngine > .form > .field > .dropdown > .menu > .item > .Tour > div {
  margin: 2.5px 0px;
}

.RecoEngine > .form > .field > .button {
  margin-top: 23px;
}

.Result {
    padding: 40px;
}

.ServiceEditDialog_ServiceEditDialog__1XsBb .MuiDialog-paper {
  min-width: 500px;
}

.ServicesPage_ServicesPage__3sO4R {
  display: grid;
  grid-template-rows: auto auto 1fr;
}
.ServicesPage_Toolbar__28M7X {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
}
.ServicesPage_Services__3E4iB {
  background-color: red;
  display: grid;
  grid-template-rows: auto 1fr;
  margin: 20px 20px 10px 20px;
}
.ServicesPage_TableContainer__2dv-n {
  overflow-y: auto;
  padding: 0 0 100px 0 !important;
}
.ServicesPage_Table__1pjRm {
  margin-bottom: 40px;
}
.ServicesPage_Table__1pjRm thead {
  background-color: #fafafa;
}
.ServicesPage_Table__1pjRm th {
  font-weight: bold !important;
}
.ServicesPage_Table__1pjRm tr {
  cursor: pointer;
}
.ServicesPage_Table__1pjRm tr:hover {
  background-color: #fafafa;
}
.ServicesPage_AddButton__1amCy {
  bottom: 20px;
  position: absolute !important;
  right: 40px;
}

.ServicesRoot_ServicesRoot__ZDIFa {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.services-legacy_ServicesHome__kq7ab {
  background-color: #ffffff;
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Services_Services__3Hyok {
  width: 450px;
  height: 100%;
}
.Services_Header__35jyc {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Services_Header__35jyc > h3 {
  margin-bottom: 0px;
}
.Services_ListHeader__9MnDQ,
.Services_Service__2WKL5 {
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  padding: 0px 10px;
  align-items: center;
}
.Services_ListHeader__9MnDQ {
  background-color: lightgrey;
  font-size: bold;
  padding-right: 20px;
}
.Services_Service__2WKL5:nth-child(2n+1) {
  background-color: white;
}
.Services_ServicesList__18cFM {
  height: calc(100% - 100px);
  width: 100%;
  overflow-y: auto;
}

.SpecialServices_SpecialServices__1fSsJ {
  width: 800px;
  height: 100%;
  margin-left: 20px;
}
.SpecialServices_Header__VJZH3 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SpecialServices_Header__VJZH3 > h3 {
  margin-bottom: 0px;
}
.SpecialServices_ListHeader__3JmH9,
.SpecialServices_SpecialService__1IAn2 {
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 2fr 5fr 4fr 4fr;
  padding: 0px 10px;
  align-items: center;
}
.SpecialServices_ListHeader__3JmH9 {
  background-color: lightgrey;
  font-size: bold;
}
.SpecialServices_SpecialService__1IAn2:nth-child(2n+1) {
  background-color: white;
}
.SpecialServices_SpecialServicesList__3FWH0 {
  height: calc(100% - 100px);
  width: 100%;
  overflow-y: auto;
}
.SpecialServices_RemoveButton__1S4jR {
  width: 40px;
}

.ServicesPage_ServicesPage__heup3 {
  width: 100%;
  height: calc(100vh - 52px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}


.Caregiver > .input > input {
  width: 100%;
}
.Caregiver > .input > input,
.Caregiver:hover > .ui.disabled.input > input,
.Caregiver > .ui.selection.dropdown,
.Caregiver > .Checkbox > .checkbox label:before {
  min-width: unset;
  border: none;
  background: none;
}
.Caregiver:hover > .Shifts,
.Caregiver:hover > .ui.input > input,
.Caregiver.isEditable:hover > .ui.selection.dropdown,
.Caregiver:hover > .Checkbox > .checkbox label:before {
  border: 1px solid rgba(34,36,38,.15);
  background: #ffffff;
  transition: border 300ms, background 300ms;
}
.Caregiver > .checkbox {
  display: flex;
  justify-content: center;
}
.Caregiver > .ui.fitted.checkbox label {
  padding-left: 1.85714em !important; /* necessary to center the checkbox */
}
.Caregiver > .ui.selection.dropdown > .icon {
  opacity: 0;
}
.Caregiver > .button {
  opacity: 0;
  width: 36px;
  height: 36px;
  justify-self: center;
}
.Caregiver:hover > .button,
.Caregiver.isEditable:hover > .ui.selection.dropdown > .icon {
  opacity: 1;
  transition: opacity 300ms;
}
.Caregiver > .button > .label {
  visibility: hidden;
}
.Caregiver > .button:hover > .label {
  z-index: 100;
  visibility: visible;
}
.Caregiver > .Shifts {
  cursor: pointer;
  width: auto;
  height: 38px;
  padding: 10px 13px;
  border-radius: .28571429rem;
  border: none;
  background: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Placeholder.ui.icon.header {
  display: block;
  margin: 10px 0px;
}

.Caregivers {
  width: 100%;
}
.Caregivers > .Headers {
  width: 100%;
  font-weight: 900;
  padding: 0px 10px;
  background-color: #cacbcd;
  height: 60px;
}
.Caregivers > .Headers div:nth-child(-n+4) {
  padding-left: 13px;
}
.Caregivers > .Headers div:nth-child(n+5) {
  text-align: center;
}
.Caregivers > a > .button {
  float: right;
  margin-top: 10px;
  margin-right: 0px;
}
.Caregivers > .CaregiversList {
  height: calc(100% - 115px);
  overflow-y: scroll;
}
.Caregivers > .CaregiversList > .Caregiver {
  width: 100%;
  height: 50px;
}
.Caregivers > .CaregiversList > .Caregiver:nth-child(2n) {
  background-color:  #fafafa;
}
.Caregivers > .CaregiversList > .button {
  margin-top: 10px;
  margin-right: 31px;
}
.Caregivers > .CaregiversList > .Caregiver.LastQualifiedCaregiver {
  border-bottom: 1px solid black;
}
.Caregivers > .Headers,
.Caregivers > .CaregiversList > .Caregiver {
  display: grid;
  grid-template-columns: 0.5fr 3fr 3fr 2fr 1fr 1fr 1fr 1fr 1fr 2fr 2fr;
  grid-column-gap: 10px;
  align-items: center;
}

.Planning > .Table {
  display: flex;
  flex-direction: column;
  outline: none;
}
.Planning > .Table > header {
  display: flex;
  height: 40px;
  background-color: #cacbcd;
  font-weight: 700;
}
.Planning > .Table > .Grid {
  outline: none;
}
.Planning > .Table .Name {
  width: 150px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  border-right: 1px solid black;
}
.Planning > .Table > header > .Day {
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Planning .ShiftCondition .icon {
  font-size: 16px;
  cursor: pointer;
}
.Planning .Cell.isEven {
  background: #fafafa;
}
.Planning .Cell.isWeekend {
  background: #ebebeb;
}
.Planning .Cell.isWeekend.isEven {
  background: #e6e6e6;
}
.Planning .Cell.isLastQualifiedCaregiver {
  border-bottom: 1px solid black;
}
.Planning .ShiftCondition.isHighlighted {
  background: #E7F7FD;
}
.Planning .ShiftCondition:hover,
.Planning .ShiftCondition.isPopupOpened {
  background: rgba(1,1,1,0.1);
}
.Planning .ShiftCondition.isDragging {
  cursor: copy;
}
.Planning .ShiftCondition .ConditionKind {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: -webkit-grab;
  cursor: grab;
}
.Planning .ShiftCondition .ConditionKind > .button {
  position: absolute;
  right: 0;
  top: 1px;
  font-size: 3px;
  background: none;
  visibility: hidden;
  cursor: pointer;
}
.Planning .ShiftCondition .ConditionKind:hover > .button {
  visibility: visible;
}
.Planning .ShiftCondition .ConditionKind > .button > .icon {
  padding-top: 5px;
}
.Planning .ShiftCondition .ConditionKind:hover > .button:hover > .icon {
  font-size: 18px;
}
.Planning > a > .button {
  float: right;
  margin: 0;
}
.ShiftConditionPopup > .ui.form > .DayTypes {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ShiftConditionPopup > .ui.form > .DayTypes > .label {
  cursor: pointer;
  opacity: 0.4;
}
.ShiftConditionPopup > .ui.form > .DayTypes > .label:hover,
.ShiftConditionPopup > .ui.form > .DayTypes > .label.isActive {
  opacity: 1;
}

.PreviousMonth {
  width: 100%;
}
.PreviousMonth > .Headers,
.PreviousMonth > .Subheaders,
.PreviousMonth > .CaregiversList > .Caregiver
 {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr 2fr 5fr;
  grid-column-gap: 20px;
  align-items: center;
  height: 50px;
}
.PreviousMonth > .Headers {
  width: 100%;
  font-weight: 900;
  background-color: #cacbcd;
  padding: 0px 20px 0px 10px;
}
.PreviousMonth > .CaregiversList {
  height: calc(100% - 105px);
  overflow-y: scroll;
}
.PreviousMonth > .CaregiversList > .Caregiver {
  padding: 0px 10px;
}
.PreviousMonth > .CaregiversList > .Caregiver.LastQualifiedCaregiver {
  border-bottom: 1px solid black;
}
.PreviousMonth > .Headers > .Header.Day,
.PreviousMonth > .CaregiversList > .Caregiver > .HasShiftOnDay {
  justify-self: center;
}
.PreviousMonth > .Headers > .Header.Weekend {
  padding-left: 20px;
}
.PreviousMonth > .Subheaders > .Subheader {
  grid-column: 3 / span 7;
  justify-self: center;
}
.PreviousMonth > .CaregiversList > .Caregiver > .ShiftOnDay6 {
  min-width: 100px;
  justify-self: center;
}
.PreviousMonth > a > .button {
  float: right;
  margin-top: 10px;
  margin-right: 0;
}


.ShiftOptimizer > .ActionBar {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ShiftOptimizer > .ActionBar > .circular.button {
  margin-right: 10px;
}
.ShiftOptimizer > .ActionBar > .input {
  min-width: 250px;
  margin-right: 10px;
}
.ShiftOptimizer > .ActionBar > .ui.primary.button {
  margin-right: 0px;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults {
  display: flex;
  flex-direction: column;
  outline: none;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > header {
  display: flex;
  height: 40px;
  background-color: #cacbcd;
  font-weight: 700;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Table,
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Footer {
  outline: none;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults .Name {
  width: 150px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  border-right: 1px solid black;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > header > .Day {
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Table > div > .Cell.isEven {
  background: #fafafa;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Table > div > .Cell.isWeekend {
  background: #ebebeb
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Table > div > .Cell.isWeekend.isEven {
  background: #e6e6e6;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Table > div > .Cell.isLastQualifiedCaregiver {
  border-bottom: 1px solid black;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Footer {
  background-color: #E7F7FD;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Footer > div > .Cell > .Title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  border-right: 1px solid black;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Footer > div > .Cell > .Title > .icon {
  color: red;
  margin-right: 7px;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults > .Footer > div > .Cell > .Title > span {
  font-weight: bold;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults .DayShiftPlan {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults .DayShiftPlan:hover {
  background: rgba(1,1,1,0.1);
}
.ShiftOptimizer > .dimmable > .ShiftOptimizerResults .DayShiftPlan > .icon {
  position: absolute;
  top: 0;
  right: 0;
  color: red;
  font-size: 10px;
}
.ui.popup.ActionBarPopup {
  width: 500px;
  height: 300px;
  overflow-y: auto;
  padding: 10px 20px;
}
.ui.popup.ActionBarPopup > .ShiftOptimizerParameters > .field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}
.ui.popup.ActionBarPopup > .ShiftOptimizerParameters > .field > .ui.input {
  width: 70px;
  margin-left: 10px;
}
.ui.popup.ActionBarPopup > .ConstraintViolations > .Constraints,
.ui.popup.DayShiftPlanPopup > .Content > .Constraints {
  color: #F3553C;
}

.Shift > .input > input,
.Shift > .ui.selection.dropdown,
.Shift > .checkbox label:before {
  min-width: unset;
  border: none;
  background: none;
}
.Shift:hover > .ui.selection.dropdown.isEditable,
.Shift.isEditable:hover > .input > input,
.Shift.isEditable:hover > .checkbox label:before {
  background: #ffffff;
  border: 1px solid #d4d4d5;
  transition: border 300ms, background 300ms;
}
.Shift > .ui.selection.dropdown > .icon {
  opacity: 0;
}
.Shift > .button {
  opacity: 0;
  width: 36px;
  height: 36px;
}
.Shift:hover > .ui.selection.dropdown.isEditable > .icon,
.Shift:hover > .button {
  opacity: 1;
  transition: opacity 300ms;
}
.Shift > .button > .label {
  visibility: hidden;
}
.Shift > .button:hover > .label {
  z-index: 100;
  visibility: visible;
}

.Shifts {
  width: 100%;
}
.Shifts > .Headers {
  width: 100%;
  font-weight: 900;
  background-color: #cacbcd;
  padding-right: 10px;
}
.Shifts > .Headers div:nth-child(2),
.Shifts > .Headers div:nth-child(3),
.Shifts > .Headers div:nth-child(4),
.Shifts > .Headers div:nth-child(5) {
  padding-left: 13px;
}
.Shifts > a > .button {
  float: right;
  margin-top: 10px;
}
.Shifts > .ShiftsList {
  height: calc(100% - 105px);
  overflow-y: scroll;
}
.Shifts > .ShiftsList > .Shift {
  width: 100%;
}
.Shifts > .ShiftsList > .Shift:nth-child(2n) {
  background-color:  #fafafa;
}
.Shifts > .ShiftsList > .Shift.LastQualifiedShift {
  border-bottom: 1px solid black;
}
.Shifts > .ShiftsList > .button {
  margin-top: 10px;
  margin-right: 31px;
}
.Shifts > .Headers,
.Shifts > .ShiftsList > .Shift {
  display: grid;
  grid-template-columns: 1fr 5fr 5fr 2fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 5fr;
  grid-column-gap: 20px;
  align-items: center;
  height: 50px;
}

.Steps {
  height: 100%;
  display: flex;
  align-items: center;
}



.ShiftPlanning {
  display: grid;
  grid-template-rows: 30px calc(100vh - 122px);
  grid-template-columns: 50% 50%;
  width: calc(100% - 20px);
  background-color: #ffffff;
  grid-row-gap: 20px;
  margin: 0 auto;
  margin-top: 20px;
}
.ShiftPlanning > div:last-child {
  grid-column: span 2;
}

.ShiftPlanningHome {
  background-color: #ffffff;
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.StationCard_CardContent__3zgA3 {
  align-items: center;
  display: grid;
  grid-template-areas:
    "avatar title"
    "table table"
    "hint hint";
  grid-template-columns: 48px 1fr;
}
.StationCard_Table__2Q2HL {
  grid-area: table;
}
.StationCard_Hint__3yvQq {
  grid-area: hint;
}

.stations_Stations__2g2N4 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}
.stations_Grid____fl5 {
  margin: 0 !important;
  overflow-y: scroll;
  width: 100% !important;
}

.TourOrderModal_OrderPlannedVisitModal__197Zt{
  max-width: 100%;
  margin-right: 0 !important;
  background: #f2f2f2;
}
.TourOrderModal_Visible__SBUha{
  opacity: 1;
}
.TourOrderModal_Hidden__1QGLU{
  opacity: 0;
}
.TourOrderModal_Header__1lxAF{
  display: block;
  text-align: center;
}
.TourOrderModal_Header__1lxAF > h3 {
  font-size: 30px;
  padding-top: 17px;
  margin-top: 0px;
}
.TourOrderModal_Nurse__3wo-H {
  max-width: 100px !important;
  margin-top: 37px;
}
.TourOrderModal_Content__TaC6S{
  margin: 40px 98px;
}
.TourOrderModal_Stations__22P2L {
  width: 87%;
}
.TourOrderModal_TourName__27Uho {
  width: 87%;
}
.TourOrderModal_Row__3HSuw {
  display: flex;
  margin-top: 25px;
  align-items: left;
  justify-content: space-between;
  text-align: left;
}
.TourOrderModal_StartTimePicker__2inol {
  margin-right: 20px;
}
.TourOrderModal_StartTimePicker__2inol, .TourOrderModal_EndTimePicker__3CxFR {
  height: 29px;
  text-align: center;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 3px;
}
.TourOrderModal_Fields__2tswq {
  display: block;
}
.TourOrderModal_Fields__2tswq > label {
  position: relative;
}
.TourOrderModal_Field__3oXjb {
  display: inline-flex;
  width: 87%;
  justify-content: space-between;
}
.TourOrderModal_CalendarIcon__1m5_5 {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 7px;
}
.TourOrderModal_StartDatePicker__3HxrA, .TourOrderModal_EndDatePicker__3jvGK {
  height: 29px;
  text-align: center;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 3px;
  width: 87%;
}
.TourOrderModal_StartDatePickerColumn__PB3jb > label > div,
.TourOrderModal_StartDatePickerColumn__PB3jb > label > div > div,
.TourOrderModal_StartDatePickerColumn__PB3jb > label > div > div input {
  width: 96% !important;
}
.TourOrderModal_StartDatePickerColumn__PB3jb > label > div > div > div {
  width: 100%;
}
.TourOrderModal_StartDatePickerLabel__3AGt2 {
  position: relative;
}
.TourOrderModal_Actions__2bWBN {
  margin-top: 66px;
  position: relative;
  width: 100%;
  text-align: center;
  padding-bottom: 60px;
}
.TourOrderModal_BookingButton__1C81M {
  width: 290px;
  margin: 0 auto !important;
  border: none;
  background: #00B0E7 !important;
  border-radius: 0 !important;
  color: #fff !important;
  height: 40px;
}

.TourOrdersTable_TableContainer__1ZgHK thead > tr > th {
  background-color: #fafafa;
}
.TourOrdersTable_TableContainer__1ZgHK tbody > tr:first-child {
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
.TourOrdersTable_TableContainer__1ZgHK tbody > tr:not(:first-child) {
  height: 55px;
}
.TourOrdersTable_TableContainer__1ZgHK tbody > tr:not(:first-child):hover {
  background-color: #e0e0e0;
}
.TourOrdersTable_TableContainer__1ZgHK tbody > tr:not(:first-child) > td {
  padding: 0;
}
.TourOrdersTable_TableContainer__1ZgHK tbody > tr:not(:first-child) > td > a {
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  width: 100%;
}
.TourOrdersTable_TableContainer__1ZgHK thead > tr > th,
.TourOrdersTable_TableContainer__1ZgHK tbody > tr:not(:first-child) > td > a {
  padding: 10px;
}

.tour-orders_TourOrders__hRKXw {
  width: 100%;
  margin: 1em 0em;
  box-shadow: none;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}
.tour-orders_CreatePlannedTourButton__3KRvQ {
  align-self: flex-end;
  margin-bottom: 15px !important;
  right: 20px;
  width: 180px;
}
.tour-orders_TourOrdersContainer__1QBh- {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 97%;
  margin: 12%;
  margin-top: 0px;
  margin-bottom: 20px;
  position: relative;
}
.tour-orders_Message__20U9i {
  max-width: 1000px;
  margin: 0 auto !important;
}
.tour-orders_TourOrdersHeader__VRVmc, .tour-orders_TourOrder__2cUo8 {
  display: grid;
  grid-template-columns: 30px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 40px;
  padding: 0 20px;
  align-items: center;
}
.tour-orders_TourOrdersHeader__VRVmc {
  height: 60px;
  font-size: 18px;
  background: linear-gradient(180deg, #00B0E7 17.19%, #0098C7 100%);
  color: #ffffff;
  font-weight: 500;
}
.tour-orders_TourOrdersHeader__VRVmc > div:first-child > div {
  width: 85%;
}
.tour-orders_TourOrdersHeader__VRVmc > div > div > input {
  box-shadow: none;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 3px;
  font-size: 12px;
  padding: 7px 10px;
  height: 30px;
}
.tour-orders_StartDateFilter__3pbR4 {
  font-size: 16px;
  padding: 4px;
  width: 7em;
}
.tour-orders_StationFilter__C0jbM, .tour-orders_CaregiverQualificationFilter__2WtM9, .tour-orders_StatusFilter__2b-hR {
  font-size: 12px;
  width: 90%;
  max-width: 90%;
  white-space: nowrap !important;
}
.tour-orders_TourOrder__2cUo8 {
  color: #3F5F7A;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 60px;
  font-weight: 500;
  text-decoration: none;
}
.tour-orders_TourOrder__2cUo8:hover {
  background: white;
  text-decoration: none;
}
.tour-orders_AcceptButton__3ti5P, .tour-orders_RejectButton__TZmBI {
  color: white !important;
}
.tour-orders_AcceptButton__3ti5P:hover, .tour-orders_RejectButton__TZmBI:hover {
  opacity: 0.7;
}
.tour-orders_FloatingAddButton__10Y47 {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5;
}
.tour-orders_FloatingUploadButton__3Yat1 {
  position: fixed;
  bottom: 20px;
  right: 120px;
  z-index: 5;
}
.tour-orders_FloatingAddButton__10Y47 > button,
.tour-orders_FloatingUploadButton__3Yat1 > button {
  background: #00B0E7 !important;
  font-size: 20px !important;
  color: #FFFFFF !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px !important;
}
.tour-orders_TourOrderButtons__328OV {
  margin-top: 20px;
}

.Task {
  border-left: 3px solid #7B7F7F;
  display: grid;
  grid-template-columns: 65px auto 100px;
  grid-template-areas: "Avatar TaskDescription TaskMetadata";
  grid-column-gap: 5px;
  padding: 10px;
  cursor: pointer;
}
.Task.new, .Task.opened {
  background: #f0f8ff;
}
.Task.health {
  border-left: 3px solid #00B0E7;
}
.Task.visit-planning {
  border-left: 3px solid #0D6FBA;
}
.Task.drug-order {
  border-left: 3px solid #47D9BF;
}
.Task:hover {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background: #f0f0f0;
}

.Tasks {
  background: white;
  height: 100%;
}
.Tasks .Subheader {
  border-bottom: 1px solid grey;
  padding: 7px;
  height: 40px;
}
.Tasks .Subheader .Filter.menu {
  height: 296px;
  width: 250px;
}
.Tasks .Subheader .item.active {
  font-weight: 900;
}
.Tasks .Subheader .menu > .header {
  font-size: unset;
}
.Tasks .Subheader .UserDropdown {
  width: 100%;
  border-radius: 0% !important;
}
.Tasks .Subheader .UserDropdown .visible.menu.transition {
  position: absolute;
  top: 36px !important;
  left: 6px;
  height: 150px;
  box-shadow: none;
  border-radius: 0% !important;
}
.Tasks .Subheader .label {
  background-color: #4AAFE2;
  color: white;
  margin-left: 10px;
}
.Tasks .Subheader .Sort {
  display: flex;
  float: right;
}
.Tasks .Subheader .Sort > .icon {
  margin-top: 3px;
  margin-right: 0px;
  cursor: pointer;
}
.Tasks .Subheader .Sort .ui.button.active {
  background-color: #4AAFE2;
}
.Tasks .TaskList {
  overflow-y: scroll;
  height: calc(100% - 40px);
}

.FeedItem {
  padding: 10px 10px;
  border-left: 3px solid darkgrey;
  cursor: pointer;
}
.FeedItem.health_update {
  border-left: 3px solid #00B0E7;
}
.FeedItem.change_service {
  border-left: 3px solid #0D6FBA;
}
.FeedItem.order_med {
  border-left: 3px solid #47D9BF;
}
.FeedItem:hover {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background: #f0f0f0;
}
.FeedItem .Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}
.FeedItem .Header .Actions i.icon {
  cursor: pointer;
}

.Feed {
  background: white;
  height: 100%;
}
.Feed .Subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  padding: 7px;
  height: 40px;
}
.Feed .Subheader .Title {
  display: flex;
  align-items: center;
}
.Feed .Subheader .Title > p {
  margin: 0;
  font-size: 18px;
}
.Feed .Subheader .Title .label {
  margin-left: 5px;
}
.Feed .Subheader .Sort {
  display: flex;
}
.Feed .Subheader .Sort > .icon {
  margin-top: 3px;
  margin-right: 0px;
  cursor: pointer;
}
.Feed .FeedItems {
  overflow-y: scroll;
  height: calc(100% - 40px);
}

.TaskManager {
  display: grid;
  grid-template-columns: auto 400px;
  grid-template-rows: 100px calc(100vh - 152px);
  grid-template-areas:
    "header header"
    "mainArea rightSidebar";
  grid-column-gap: 30px;
  margin: 0 auto;
}
.TaskManager > .Header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.TaskManager > .Header h3 {
  color: #0D6FBA;
  margin-bottom: 5px;
}
.TaskManager > .Header > .FilterButtons > button {
  border: none;
  padding: 5px;
  background: transparent;
  color: #0D6FBA;
  opacity: 0.6;
  outline: 0;
  cursor: pointer;
}
.TaskManager > .Header > .FilterButtons > button.active {
  font-weight: bold;
  opacity: 1;
}
.TaskManager > .MainArea {
  grid-area: mainArea;
  background: #fafafa;
  height: 100%;
}
.TaskManager > .RightSidebar {
  grid-area: rightSidebar;
  background: white;
  height: 100%;
}
.TaskManager .MainInput {
  grid-area: auto;
}
.TaskManager .ui.divider {
  margin: 0px !important;
}
p.NoEntry {
  text-align: center;
  padding-top: 50px;
}
.ModalOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.85);
  left: 0;
  z-index: 3;
}

.SearchBar_SearchBar__2kk01 {
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 40px;
  padding: 0 4px;
}
.SearchBar_SearchBar__2kk01 .MuiIconButton-root {
  height: 32px;
  padding: 0;
  width: 32px;
}
.SearchBar_SearchBar__2kk01.SearchBar_HiddenPlaceholderOnBlur__1pnWu input::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: color 0.1s;
  transition: color 0.1s;
}
.SearchBar_SearchBar__2kk01.SearchBar_HiddenPlaceholderOnBlur__1pnWu input::placeholder {
  color: transparent;
  transition: color 0.1s;
}
.SearchBar_SearchBar__2kk01.SearchBar_HiddenPlaceholderOnBlur__1pnWu input:focus::-webkit-input-placeholder, .SearchBar_SearchBar__2kk01.SearchBar_HiddenPlaceholderOnBlur__1pnWu input:hover::-webkit-input-placeholder {
  color: initial;
}
.SearchBar_SearchBar__2kk01.SearchBar_HiddenPlaceholderOnBlur__1pnWu input:focus::placeholder,
.SearchBar_SearchBar__2kk01.SearchBar_HiddenPlaceholderOnBlur__1pnWu input:hover::placeholder {
  color: initial;
}
.SearchBar_ClearButton__15kfl {
  opacity: 0;
  transition: opacity 0.1s;
}
.SearchBar_SearchBar__2kk01:hover .SearchBar_ClearButton__15kfl,
.SearchBar_SearchBar__2kk01:focus-within .SearchBar_ClearButton__15kfl {
  opacity: 1;
}
.SearchBar_ClearButtonHidden__3gkNS {
  visibility: hidden;
}

.AddressSearchBar_Menu__2i5Lm .MuiMenu-paper {
  min-width: 200px;
}

.TextEditor_Input__3z1P8.TextEditor_small__1mudW input,
.TextEditor_Input__3z1P8.TextEditor_tiny__3HSYe input,
.TextEditor_Input__3z1P8.TextEditor_mini__1XDug input {
  padding: 0px 2px !important;
}

.PlannedActivity_PlannedActivity__3UBnV {
  overflow: visible !important; /* The selection count is overflowing */
  padding: 5px;
  position: relative;
  --drag-color: #3F5F7A;
}
.PlannedActivity_PlannedActivity__3UBnV:focus {
  outline: 0;
}
.PlannedActivity_PlannedActivity__3UBnV.PlannedActivity_IsSelected__1K2GE {
  color: #ffffff;
  background: #3F5F7A;
}
.PlannedActivity_IsRescheduled__2OzxM {
  opacity: 0.5;
}
.PlannedActivity_IsRescheduledPlannedVisitDeleted__HfpIO {
  background: #ff4d4d !important;
}
.PlannedActivity_Header__3MlfU {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 40px auto;
  height: 44px;
  justify-content: space-between;
}
.PlannedActivity_PlannedVisitHeader__3C7Sq {
  display: grid;
  grid-template-columns: auto 20px;
}
.PlannedActivity_Name__Z274M {
  overflow: hidden;
  text-overflow: ellipsis;
}
.PlannedActivity_Code__25ql1 {
  font-weight: bold;
  margin-right: 4px;
  text-transform: uppercase;
}
.PlannedActivity_PlannedVisitIcons__1u1hh {
  align-items: center;
  color: #ffd700;
  display: flex;
  justify-content: center;
}
.PlannedActivity_Time__2PUnx {
  display: grid;
  font-weight: bold;
  line-height: 1em;
  position: relative;
  grid-template-rows: 1fr 1fr 1fr;
  text-align: right;play: flex;
}
.PlannedActivity_TimeDisabled__3miBD {
  color: #c0c0c080;
}
.PlannedActivity_MissingLocationWarning__1DLvY {
  align-items: center;
  bottom: 0;
  display: flex;
  color: #aa0000;
  justify-content: center;
  left: 0;
  padding-left: 10px;
  position: absolute;
  right: 0;
  top: 0;
}
.PlannedActivity_Index__XUebZ {
  background-color: silver;
  border-radius: 50%;
  display: flex;
  font-size: 10px;
  justify-content: center;
  margin-left: 4px;
  width: 20px;
}
.PlannedActivity_Qualification__1IEZe .PlannedActivity_Index__XUebZ {
  background-color: var(--unknown-qualification-dark-color);
}
.PlannedActivity_RequiresQualifiedCaregiver___m_ja .PlannedActivity_Index__XUebZ {
  background-color: var(--qualified-caregiver-color);
}
.PlannedActivity_RequiresCareCompanion__3cJ8C .PlannedActivity_Index__XUebZ {
  background-color: var(--care-companion-color);
}
.PlannedActivity_RequiresCareCompanionWithSgbVOnlyServices__1Jeuo .PlannedActivity_Index__XUebZ {
  background-color: var(--care-companion-sgb-v-only-services-color);
}
.PlannedActivity_StartOffset__2x1DZ {
  color: darkred;
}
.PlannedActivity_AdditionalDrivingTimeIndicator__1w8-e {
  color: blue;
  margin: 0px !important;
  position: absolute;
  right: -10px;
  top: -4px;
}
.PlannedActivity_Qualification__1IEZe {
  border-left: 5px solid var(--unknown-qualification-color);
}
.PlannedActivity_RequiresQualifiedCaregiver___m_ja {
  border-left-color: var(--qualified-caregiver-color);
}
.PlannedActivity_RequiresCareCompanion__3cJ8C {
  border-left-color: var(--care-companion-color);
}
.PlannedActivity_RequiresCareCompanionWithSgbVOnlyServices__1Jeuo {
  border-left-color: var(--care-companion-sgb-v-only-services-color);
}
.PlannedActivity_RejectedOrCancelled__zqu7y {
  opacity: 0.3;
}
.PlannedActivity_ExpandButton__2vQYA {
  display: none !important;
  left: 50%;
  margin-left: auto;
  position: absolute !important;
  top: 34px;
  transform: translate(-50%, 0) rotate(0deg);
}
.PlannedActivity_ExpandButton__2vQYA.PlannedActivity_IsExpanded__2_lS0 {
  transform: translate(-50%, 0) rotate(180deg);
}
.PlannedActivity_PlannedActivity__3UBnV .MuiCollapse-container {
  padding: 8px 0;
}
.PlannedActivity_PlannedActivity__3UBnV:hover .PlannedActivity_ExpandButton__2vQYA {
  display: inline-block !important;
}
.PlannedActivity_IsSelected__1K2GE .PlannedActivity_StartOffset__2x1DZ {
  color: #ffffff;
}
.PlannedActivity_SelectionCount__3JxPl {
  right: -15px;
  top: -15px;
  color: #ffffff;
  background: var(--drag-color);
  border-radius: 50%;
  height: 25px;
  width: 25px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
}
.PlannedActivity_TourOrderLink__yyzZ8 {
  margin-bottom: 5px;
}
.PlannedActivity_HistoricalTimes__2Asdi, .PlannedActivity_PlannedTimes__J6-Xd {
  display: grid;
  grid-template-areas: "title times";
  grid-template-columns: auto 1fr;
}
.PlannedActivity_PlannedTimes__J6-Xd {
  margin-bottom: 6px;
}
.PlannedActivity_HistoricalTimes__2Asdi span:nth-child(1), .PlannedActivity_PlannedTimes__J6-Xd span:nth-child(1) {
  font-weight: bold;
}
.PlannedActivity_HistoricalTimes__2Asdi span:nth-child(2), .PlannedActivity_PlannedTimes__J6-Xd span:nth-child(2) {
  margin-right: 4px;
  text-align: right;
}
.PlannedActivity_ServicesTitle__1Jgnn {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 9px !important;
  display: block;
}
.PlannedActivity_ServiceAbbreviation__gwkQv {
  font-weight: bold;
}
.PlannedActivity_Service__3RTE1 {
  list-style: circle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.PlannedActivity_DrivingDurationAndVisitDuration__1zf-P {
  display: flex;
  flex-flow: row;
  margin-bottom: 6px;
}
.PlannedActivity_Duration__2AiDY, .PlannedActivity_DrivingTime__vaYA4 {
  display: flex;
  flex-flow: row;
  font-size: 12px;
  margin-right: 4px;
}
.PlannedActivity_HintIcon__2k-JV {
  margin-left: auto;
}
.PlannedActivity_AdditionalDrivingDuration__3CmPV {
  align-items: center;
  display: flex;
}
.PlannedActivity_AdditionalDrivingDurationEditor__tak6n {
  margin-left: 5px !important;
  margin-right: 2px !important;
  width: 40px !important;
}
.PlannedActivity_AdditionalDrivingDurationEditor__tak6n input {
  text-align: right;
}
.PlannedActivity_IdCopy__ch-H3 {
  bottom: 2px;
  position: absolute !important;
  right: 2px;
}
.PlannedActivity_PlannedBreakHeader__2LcJh {
  align-items: center;
  display: flex;
}
.PlannedActivity_DurationEditor__OsHxP {
  margin-left: 4px !important;
  margin-right: 4px !important;
  width: 40px !important;
}
.PlannedActivity_DurationEditor__OsHxP input {
  text-align: right;
}
.PlannedActivity_PlannedGenericActivityDuration__1YQSR {
  align-items: center;
  display: flex;
}

.Map_Map__1npDg {
  display: grid;
  grid-template-areas:
    "search-bar"
    "internal-map";
  grid-template-rows: auto 1fr;
}
.Map_AddressSearchBar__131ZE {
  grid-area: search-bar;
  margin: 10px;
}
.Map_InternalMap__2U8wp {
  grid-area: internal-map;
}
.Map_InternalMap__2U8wp .gm-style {
  font: inherit !important; /* Overwrite google maps's font style which affects the markers */
}
.Map_TourPlannedVisit__330xh {
  --background-color: #ffffff;
  --border-box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}
.Map_TourPlannedVisit__330xh.Map_Qualification__2MFOw {
  --color: var(--unknown-qualification-dark-color);
}
.Map_TourPlannedVisit__330xh.Map_RequiresQualifiedCaregiver__2l_qR {
  --color: var(--qualified-caregiver-color);
}
.Map_TourPlannedVisit__330xh.Map_RequiresCareCompanion__36rSC {
  --color: var(--care-companion-color);
}
.Map_TourPlannedVisit__330xh.Map_RequiresCareCompanionWithSgbVOnlyServices__1r57z {
  --color: var(--care-companion-sgb-v-only-services-color);
}
.Map_UnscheduledPlannedVisit__2aU7P {
  --border-color: var(--background-color);
  --border-opacity: 0.5;
  --border-width: 6px;
  --box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  --color: #ffffff;
}
.Map_UnscheduledPlannedVisit__2aU7P.Map_Qualification__2MFOw {
  --background-color: var(--unknown-qualification-dark-color);
}
.Map_UnscheduledPlannedVisit__2aU7P.Map_RequiresQualifiedCaregiver__2l_qR {
  --background-color: var(--qualified-caregiver-color);
}
.Map_UnscheduledPlannedVisit__2aU7P.Map_RequiresCareCompanion__36rSC {
  --background-color: var(--care-companion-color);
}
.Map_UnscheduledPlannedVisit__2aU7P.Map_RequiresCareCompanionWithSgbVOnlyServices__1r57z {
  --background-color: var(--care-companion-sgb-v-only-services-color);
}
.Map_PlannedActivityHint__3MZve {
  width: 200px;
}

.PlannedTourCopyingModal_DatePicker__32nZ0 {
  display: inline-block !important;
  margin-bottom: 20px;
  width: 100%;
}
.PlannedTourCopyingModal_Result__tdhsF li {
  padding-bottom: 0;
  padding-top: 0;
}
.PlannedTourCopyingModal_Result__tdhsF li div {
  margin-bottom: 0;
  margin-top: 0;
}

.DraggablePlannedActivity_IsDragging__1958g {
  background: var(--drag-color) !important;
  color: #ffffff;
  border: 1px solid #E9E9E9;
}
.DraggablePlannedActivity_IsGhosting__1M37B {
  opacity: 0.3;
}


.TimePicker_TimePicker__194zU {
  width: 7em !important;
}
.TimePicker_small__2dC8A,
.TimePicker_tiny__3P_9V,
.TimePicker_mini__aejEK {
  width: inherit !important;
}
.TimePicker_small__2dC8A input,
.TimePicker_tiny__3P_9V input,
.TimePicker_mini__aejEK input {
  padding: 0px !important;
}
.TimePicker_Input__1BGmF {
  margin-top: 2px;
}

a.TimeEditor_small__1lJLI,
a.TimeEditor_tiny__3Zngg,
a.TimeEditor_mini__oRpdi {
  padding: 2px !important;
}

.PlannedTourHeader_PlannedTourHeader__24iAQ {
  --padding: 8px;
  background: #ffffff;
  color: #3F5F7A;
  display: grid;
  grid-template-areas:
    "name name menu-buttons"
    "caregiver caregiver caregiver"
    "time time time"
    "caregiver-qualification driving-time duration";
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: auto 20px 32px auto;
  padding: var(--padding);
  padding-top: 2px;
}
.PlannedTourHeader_Name__15cFN {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  grid-area: name;
  overflow: hidden;
}
.PlannedTourHeader_MenuButtons__1-UQ7 {
  display: flex;
  grid-area: menu-buttons;
  justify-content: flex-end;
  left: var(--padding); /* Move menu buttons to the very right */
  position: relative;
}
.PlannedTourHeader_Checkbox__1Yyro, .PlannedTourHeader_MenuButton__25ZCC {
  height: 26px;
  width: 26px;
}
.PlannedTourHeader_Time__2TlX6 {
  grid-area: time;
}
.PlannedTourHeader_Time__2TlX6 input {
  padding: 2px 8px !important;
}
.PlannedTourHeader_Caregiver__9mfjZ {
  font-size: 15px;
  grid-area: caregiver;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PlannedTourHeader_Duration__20ucs, .PlannedTourHeader_DrivingTime__2D2Cx {
  display: flex;
  justify-content: flex-end;
}
.PlannedTourHeader_Duration__20ucs {
  grid-area: duration;
}
.PlannedTourHeader_DrivingTime__2D2Cx {
  grid-area: driving-time;
}
.PlannedTourHeader_CaregiverQualification__2P4kc {
  align-items: center;
  background-color: gray;
  border-radius: 5px;
  color: white;
  display: flex;
  font-size: 12px;
  grid-area: caregiver-qualification;
  justify-content: center;
  width: 40px;
}
.PlannedTourHeader_CareCompanion__1UINl {
  background-color: var(--care-companion-color);
}
.PlannedTourHeader_QualifiedCaregiver__3l7kl {
  background-color: var(--qualified-caregiver-color);
}

.PlannedTour_PlannedTour__3vEW9 {
  align-items: center;
  background: #f0f0f0;
  border-radius: 3px;
  margin-bottom: 5px;
  position: relative;
  transition: opacity 200ms;
}
.PlannedTour_Loader__2cfhO {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  top: 60px !important;
}
.PlannedTour_Collapsed__22Odv {
  opacity: 0.5;
}
.PlannedTour_Color__IA3Ju {
  height: 5px;
  width: 100%;
}
.PlannedTour_ExpandButton__x2bD_ {
  position: absolute !important;
  right: 8px;
  top: 40px;
  transform: rotate(0deg);
  z-index: 1;
}
.PlannedTour_ExpandButton__x2bD_.PlannedTour_IsExpanded__3i_xw {
  transform: rotate(180deg);
}
.PlannedTour_Expansion__1GPYu {
}
.PlannedTour_PlannedActivities__3ObCY {
  height: 100%;
  min-height: 80px; /* Minimum space to drop planned visits when the list of visits is empty */
  transition: opacity 300ms, -webkit-filter 300ms;
  transition: filter 300ms, opacity 300ms;
  transition: filter 300ms, opacity 300ms, -webkit-filter 300ms;
}
.PlannedTour_PlannedActivities__3ObCY.PlannedTour_Loading__22w-r {
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0.5;
}
.PlannedTour_PlannedActivity__32dZW {
  margin-top: 5px;
}

.PlannedTours_PlannedTours__36h2w {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 2px;
  position: relative;
}
.PlannedTours_Toolbar__1ygWB {
  margin: 10px 0 0 10px;
}
.PlannedTours_SearchBar__2mytJ {
  width: 100%;
}
.PlannedTours_Grid__35Y3u {
  display: flex;
  flex-flow: row wrap;
  overflow-y: auto;
  padding-top: 10px;
  transition: opacity 300ms, -webkit-filter 300ms;
  transition: filter 300ms, opacity 300ms;
  transition: filter 300ms, opacity 300ms, -webkit-filter 300ms;
}
.PlannedTours_Grid__35Y3u.PlannedTours_Loading__1PbSF {
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0.5;
}
.PlannedTours_Grid__35Y3u > * {
  margin: 0 10px 20px 10px;
  width: 200px;
}

.EventPropagationStopper_EventPropagationStopper__1sFGL {
  display: inline-flex;
}

.Toolbar_Toolbar__1yXj6 {
  margin: 0 0 1px 0 !important;
}
.Toolbar_Toolbar__1yXj6 .MuiAccordionSummary-root {
  align-items: start;
  display: grid !important;
  grid-template: "expand-button summary-content" / auto 1fr;
  min-height: initial !important;
  padding: 0 !important;
}
.Toolbar_Toolbar__1yXj6 .MuiAccordionSummary-root.Mui-focused {
  background-color: transparent; /* Unset panel background color (set when date picker receives focus) */
}
.Toolbar_Toolbar__1yXj6 .MuiAccordionSummary-content {
  grid-area: summary-content;
  margin: 0 !important;
}
.Toolbar_Toolbar__1yXj6 .MuiAccordionSummary-expandIcon {
  grid-area: expand-button;
}
.Toolbar_Toolbar__1yXj6 .MuiAccordionSummary-expandIcon:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.Toolbar_Toolbar__1yXj6 .MuiAccordionDetails-root {
  background-color: #fafafa;
  display: grid !important;
  grid-template: auto 1fr / 1fr;
  padding: 0 !important;
}
.Toolbar_Chips__1xkGp {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 0 8px;
  width: 100%;
}
.Toolbar_Chips__1xkGp > * {
  height: 32px;
  margin-bottom: 8px; /* margin between rows when chips span multiple rows */
  margin-right: 8px;
}
.Toolbar_NoFilterLabel__28GL5 {
  align-items: center;
  color: #808080;
  display: flex;
}
.Toolbar_Chip__15Iko > .MuiChip-label {
  font-size: 14px;
}
.Toolbar_Chip__15Iko.Toolbar_Unscheduled__2_w1B {
  background-color: #455F78 !important;
  color: white !important;
}
.Toolbar_Chip__15Iko.Toolbar_Unscheduled__2_w1B:focus {
  background-color: #33475a !important;
}
.Toolbar_Chip__15Iko.Toolbar_Unscheduled__2_w1B > .MuiChip-deleteIcon {
  color: lightgray !important;
}
.Toolbar_Chip__15Iko.Toolbar_Unscheduled__2_w1B > .MuiChip-deleteIcon:hover {
  color: white !important;
}
.Toolbar_UnscheduledTimeRange__2TAX_ {
  width: 480px !important;
  margin: 40px 20px 10px 20px;
}
.Toolbar_StationPicker__3_n9U, .Toolbar_DatePicker__JZWDQ {
  height: 32px;
  margin: 8px 0 0 0 !important;
}
.Toolbar_DatePicker__JZWDQ {
  margin-left: 8px !important;
  padding-top: 2px !important;
  width: 134px;
}
.Toolbar_DatePicker__JZWDQ > .MuiInput-underline::before {
  opacity: 0; /* hides underline as long as date picker is not focused */
}
.Toolbar_DatePicker__JZWDQ .MuiInputAdornment-positionEnd {
  margin-left: 0;
}
.Toolbar_DatePicker__JZWDQ .MuiIconButton-root {
  opacity: 0.3;
  transition: opacity 0.1s;
}
.Toolbar_DatePicker__JZWDQ:hover .MuiIconButton-root,
.Toolbar_DatePicker__JZWDQ .MuiInput-input:active ~ .MuiInputAdornment-root .MuiIconButton-root,
.Toolbar_DatePicker__JZWDQ .MuiInput-input:focus ~ .MuiInputAdornment-root .MuiIconButton-root {
  opacity: 1.0;
}
.Toolbar_Buttons__25vj- {
  display: flex;
  padding: 4px;
}
.Toolbar_ButtonGroup__1QIn8 {
  background-color: transparent !important;
}
.Toolbar_ButtonGroup__1QIn8:not(:first-child) {
  margin-left: 8px;
}
.Toolbar_ButtonGroup__1QIn8 > .MuiTypography-root {
  margin: 0 6px;
}

.UnscheduledPlannedVisits_UnscheduledPlannedVisits__kQNRW {
  overflow-y: auto;
  padding: 4px;
  position: relative; /* Otherwise <Loader> is not position properly */
}
.UnscheduledPlannedVisits_Droppable__1JcqB {
  transition: opacity 0.3s, -webkit-filter 0.3s;
  transition: filter 0.3s, opacity 0.3s;
  transition: filter 0.3s, opacity 0.3s, -webkit-filter 0.3s;
}
.UnscheduledPlannedVisits_Droppable__1JcqB.UnscheduledPlannedVisits_Loading__-bo_q {
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0.5;
}
.UnscheduledPlannedVisits_PlannedVisit__4_UxN:not(:first-child) {
  margin-top: 5px;
}

.tour-planner2_TourPlanner__13XPb {
  display: grid;
  grid-template-areas:
    "toolbar"
    "tours-and-visits-and-map";
  grid-template-rows: auto 1fr;
}
.tour-planner2_Toolbar__2OAst {
  grid-area: "toolbar";
  margin: 1px;
}
.tour-planner2_ToursAndVisitsAndMap__2kxva {
  grid-area: tours-and-visits-and-map;
  height: auto !important; /* Override SplitterLayout's `100%` which breaks the layout */
  position: relative !important; /* Override SplitterLayout's `absolute` which breaks the layout */
}
.tour-planner2_ToursAndVisits__3mynb {
  display: grid;
  grid-area: tours_and_visits;
  grid-template-areas: "planned_tours unscheduled_visits";
  grid-template-columns: 1fr 230px;
  grid-template-rows: 100%;
  height: 100%;
}
.tour-planner2_PlannedTours__1U-nY {
  grid-area: planned_tours;
}
.tour-planner2_UnscheduledPlannedVisits__1aluY {
  grid-area: unscheduled_visits;
}
.tour-planner2_Map__2J8V0 {
  height: 100%;
}

.TourPlanner2Home_TourPlanner2Home__2h-z- {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*---------------------------
 This StyleSheet considered as
 Universal stylesheet for the entire App

 Naming Convention:
---------------------------*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro','Open Sans', sans-serif;
}
#root {
  height: 100vh;
}
a {
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}
.modal {
  height: auto;
}
.ui.blue.header {
  color: #00b0e7 !important;
}
.ui.blue.button, .ui.blue.buttons .button {
  background-color: #00b0e7 !important;;
}
.ui.segment {
  background-color: #F6F6F6;
  height: 100%;
  padding: 0;
  border: 0;
}
.ui.search.selection.dropdown .search.icon {
  padding-top: 9px !important;
}
.ui.primary.button {
  background-color: #00b0e7
}
.ui.disabled.button {
  opacity: .40 !important;
}
.ui.secondary.button {
  background-color: #ffffff;
  color: black;
  border: 1px solid #7b7f7f;
}
.ui.modal > .content {
  padding: 1rem;
}
.ui.modal > .header {
  padding: 1rem;
}
.ui.dropdown > a {
  text-decoration: none;
}
.ui.modals {
  z-index: 10000 !important; /* TODO: Remove once we stop using Semantic.Modal */
}

/*---------------------------------------------------------------------*/
/*-------------- For calendar on Order page(/orders/ ------------------*/
/*---------------------------------------------------------------------*/
.CalendarTransition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.CalendarTransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.CalendarTransition-exit {
  opacity: 1;
}
.CalendarTransition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.rbc-calendar .rbc-month-view {
  background: #ffffff;
}

.ui.checkbox {
  margin-right: 20px !important;
}

