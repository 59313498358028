.FeedItem {
  padding: 10px 10px;
  border-left: 3px solid darkgrey;
  cursor: pointer;
}
.FeedItem.health_update {
  border-left: 3px solid #00B0E7;
}
.FeedItem.change_service {
  border-left: 3px solid #0D6FBA;
}
.FeedItem.order_med {
  border-left: 3px solid #47D9BF;
}
.FeedItem:hover {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background: #f0f0f0;
}
.FeedItem .Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}
.FeedItem .Header .Actions i.icon {
  cursor: pointer;
}
