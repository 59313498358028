.Forbidden {
  max-width: 90%;
  display: block;
  position: relative;
  margin: 50px auto;
}
.Message {
  max-width: 1000px;
  margin: 0 auto !important;
}
