.LogoutScreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.LogoutScreen > .LogoutForm {
  width: 450px;
}

.LogoutScreen > .LogoutForm > .header {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px;
}

.LogoutScreen > .LogoutForm > .header > span {
  font-size: 26px;
  color:  #00B0E7;
}

.LogoutScreen > .LogoutForm > .header > .image {
  width: 150px;
}
