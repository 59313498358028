.Task {
  border-left: 3px solid #7B7F7F;
  display: grid;
  grid-template-columns: 65px auto 100px;
  grid-template-areas: "Avatar TaskDescription TaskMetadata";
  grid-column-gap: 5px;
  padding: 10px;
  cursor: pointer;
}
.Task.new, .Task.opened {
  background: #f0f8ff;
}
.Task.health {
  border-left: 3px solid #00B0E7;
}
.Task.visit-planning {
  border-left: 3px solid #0D6FBA;
}
.Task.drug-order {
  border-left: 3px solid #47D9BF;
}
.Task:hover {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background: #f0f0f0;
}
