.SearchBar {
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 40px;
  padding: 0 4px;
}
.SearchBar :global(.MuiIconButton-root) {
  height: 32px;
  padding: 0;
  width: 32px;
}
.SearchBar.HiddenPlaceholderOnBlur input::placeholder {
  color: transparent;
  transition: color 0.1s;
}
.SearchBar.HiddenPlaceholderOnBlur input:focus::placeholder,
.SearchBar.HiddenPlaceholderOnBlur input:hover::placeholder {
  color: initial;
}
.ClearButton {
  opacity: 0;
  transition: opacity 0.1s;
}
.SearchBar:hover .ClearButton,
.SearchBar:focus-within .ClearButton {
  opacity: 1;
}
.ClearButtonHidden {
  visibility: hidden;
}
