.FloatingMenuItem {
    position: fixed;
    right: 33px;
    bottom: 85px;
    text-align: right;
    z-index: 5;
}
.FloatingMenuItem .InputMenuItem {
    margin-bottom: 10px;
    cursor: pointer;
}
.FloatingMenuItem .InputMenuItem button {
    background: #0D6FBA;
    color: #FFFFFF;
    font-size: 16px;
    padding: 8px !important;
    margin: 0;
}
.FloatingMenuItem .InputMenuItem button:hover {
    color: #FFFFFF;
}
.FloatingMenuItem .InputMenuItem.Gesundheitszustand button {
    background: #00B0E7;
}
.FloatingMenuItem .InputMenuItem.General button {
    background: #7B7F7F;
}
.FloatingMenuItem .InputMenuItem.Tourenplanung button:hover {
    background: #0D6FBA;
}
.FloatingMenuItem .InputMenuItem .label{
    display: inline-block;
    margin-right: 8px;
    padding: 4px 10px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 1px 1px 1px #cfcfcf;
    transition: opacity 250ms ease-in-out;
}
.FloatingMenuItem .InputMenuItem img {
    width: 32px;
    padding: 6px;
    display: inline-block;
    vertical-align: middle;
    background: #47D9BF;
    border-radius: 50%;
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    text-align: center;
    color: #ffffff !important;
}