.TaskMetadata {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.TaskMetadata .Status {
  color: #00B0E7;
}
.TaskMetadata .Assignment {
  display: flex;
  justify-content: center;
  align-items: center;
}
.TaskMetadata .Assignment .SmallAvatar {
  margin-left: -7px;
  z-index: 2;
}
.TaskMetadata .Assignment .SmallAvatar.FormSubtask {
  z-index: 1;
  position: relative;
}
.TaskMetadata .Assignment .DueDate {
  margin: 0 5px 0px 0px;
  padding-right: 5px;
}
.TaskMetadata .Status {
  font-size: 12px;
}

