.ClientPage {
    display: grid;
    grid-template-columns: 300px auto;
    grid-template-rows: 100px calc(100vh - 152px);
    grid-template-areas:
      "header header"
      "leftSidebar mainArea";
    grid-column-gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}
.ClientPage > .MainArea {
    grid-area: mainArea;
    background: #fafafa;
    height: 100%;
    display: grid;
    grid-template-rows: 36px calc(100% - 36px);
}
.ClientPage > .MainArea .FilterButtons {
    padding: 7px 0px 10px 0px;
}
.ClientPage > .LeftSidebar {
    background: #fff;
    border: 1px solid #fcfcfc;
    padding: 20px 15px;
    grid-area: leftSidebar;
    height: 100%;
    overflow-y: scroll;
}
.ClientPage > .Header {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.ClientPage > .Header h3 {
    color: #0D6FBA;
    margin-bottom: 5px;
}
.ClientPage  > .MainArea  > .FilterButtons > button {
    border: none;
    padding: 5px;
    background: transparent;
    color: #0D6FBA;
    opacity: 0.6;
    outline: 0;
    cursor: pointer;
}
.ClientPage  > .MainArea  > .FilterButtons > button.active {
    font-weight: bold;
    opacity: 1;
}
