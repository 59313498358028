.MenuWithDropdown {
  color: #ffffff !important;
}
.MenuWithDropdown a {
  color: black;
}
.Active {
  background-color: rgba(0,0,0, 0.18) !important;
}
.DropdownItem:hover {
  background-color: rgba(0,0,0, 0.1) !important;
}
