.Shift > .input > input,
.Shift > .ui.selection.dropdown,
.Shift > .checkbox label:before {
  min-width: unset;
  border: none;
  background: none;
}
.Shift:hover > .ui.selection.dropdown.isEditable,
.Shift.isEditable:hover > .input > input,
.Shift.isEditable:hover > .checkbox label:before {
  background: #ffffff;
  border: 1px solid #d4d4d5;
  transition: border 300ms, background 300ms;
}
.Shift > .ui.selection.dropdown > .icon {
  opacity: 0;
}
.Shift > .button {
  opacity: 0;
  width: 36px;
  height: 36px;
}
.Shift:hover > .ui.selection.dropdown.isEditable > .icon,
.Shift:hover > .button {
  opacity: 1;
  transition: opacity 300ms;
}
.Shift > .button > .label {
  visibility: hidden;
}
.Shift > .button:hover > .label {
  z-index: 100;
  visibility: visible;
}
