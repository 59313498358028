.FormContent {
  margin: 40px;
  border-radius: 3px;
  text-align: center;
}
.FileUploadStatus {
  border: 2px dashed #999;
  position: relative;
  display: block;
  padding-top: 75px;
}
.UploadStatusCloud {
  width: 250px !important;
  margin: 0 auto !important;
}
.FileUploadTitle {
  font-weight: 600;
  font-size: 24px;
  margin: 5px 0px;
  color: #3F5F7A;
}
.FileUploadStatus > span {
  font-size: 16px;
  text-align: center;
  display: block;
  line-height: 24px;
  color: #B8C5D0;
}
.Error h4 {
  color: #ED5454 !important;
}
.Error > button {
  background: #ED5454 !important;
  border: 1px solid #FF826E !important;
}
.Success h4 {
  color: #3F5F7A;
}
.Success > button {
  background: #FFD47C !important;
  border: 1px solid #FFCB60 !important;
}
.FileUploadStatusButton {
  width: 290px;
  border-radius: 2px;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff !important;
  display: inline-block;
  margin: 44px auto 0 auto !important;
  height: 40px;
  background: #00B0E7 !important;
  border: 1px solid #0098C7 !important;
}
.ErrorMessages {
  color: #ED5454;
  margin-top: 20px;
}
