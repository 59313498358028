.StationPicker > .list  {
  min-width: 200px;
  margin-top: 0px;
  font-size: 16px;
}
.StationPicker > .list > a > .item {
  padding: 5px;
}
.StationPicker > .list > a > .item:hover {
  background-color: #fafafa;
}
