.Subtask {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.Subtask > .SubtaskDescription {
  display: flex;
}
.Subtask > .SubtaskDescription .PersonAvatar {
  margin-right: 10px;
}
.Subtask > .SubtaskDescription .Content .Metadata {
  color: #7b7f7f;
  font-size: 12px;
  font-weight: 100;
  display: flex;
  justify-content: space-between;
}
.Subtask > .SubtaskDescription .Content .Metadata p {
  display: inherit;
}
.Subtask > .SubtaskDescription  .Metadata p span {
  border-bottom: 1px dashed #ccc;
  padding: 0 3px;
}
.Subtask .Actions {
  display: flex;
  align-items: center;
  align-self: center;
  margin-bottom: 0px;
  padding-right: 5px;
}
.Subtask .Actions > * {
  cursor: pointer;
}
.Subtask .Actions .button {
  width: 40px;
  padding: 0px;
  margin-right: 0px;
  box-shadow: none !important;
}
.Subtask .Actions .Duedate {
  width: 40px;
}
.Subtask .Actions .disabled {
  opacity: 0.2;
  cursor: unset;
}
