.TableContainer thead > tr > th {
  background-color: #fafafa;
}
.TableContainer tbody > tr:first-child {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}
.TableContainer tbody > tr:not(:first-child) {
  height: 55px;
}
.TableContainer tbody > tr:not(:first-child):hover {
  background-color: #e0e0e0;
}
.TableContainer tbody > tr:not(:first-child) > td {
  padding: 0;
}
.TableContainer tbody > tr:not(:first-child) > td > a {
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  width: 100%;
}
.TableContainer thead > tr > th,
.TableContainer tbody > tr:not(:first-child) > td > a {
  padding: 10px;
}
