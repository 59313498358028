.PlannedTourHeader {
  --padding: 8px;
  background: #ffffff;
  color: #3F5F7A;
  display: grid;
  grid-template-areas:
    "name name menu-buttons"
    "caregiver caregiver caregiver"
    "time time time"
    "caregiver-qualification driving-time duration";
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: auto 20px 32px auto;
  padding: var(--padding);
  padding-top: 2px;
}
.Name {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  grid-area: name;
  overflow: hidden;
}
.MenuButtons {
  display: flex;
  grid-area: menu-buttons;
  justify-content: flex-end;
  left: var(--padding); /* Move menu buttons to the very right */
  position: relative;
}
.Checkbox, .MenuButton {
  height: 26px;
  width: 26px;
}
.Time {
  grid-area: time;
}
.Time input {
  padding: 2px 8px !important;
}
.Caregiver {
  font-size: 15px;
  grid-area: caregiver;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Duration, .DrivingTime {
  display: flex;
  justify-content: flex-end;
}
.Duration {
  grid-area: duration;
}
.DrivingTime {
  grid-area: driving-time;
}
.CaregiverQualification {
  align-items: center;
  background-color: gray;
  border-radius: 5px;
  color: white;
  display: flex;
  font-size: 12px;
  grid-area: caregiver-qualification;
  justify-content: center;
  width: 40px;
}
.CareCompanion {
  background-color: var(--care-companion-color);
}
.QualifiedCaregiver {
  background-color: var(--qualified-caregiver-color);
}
