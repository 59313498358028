.TourPlanner {
  display: grid;
  grid-template-areas:
    "toolbar"
    "tours-and-visits-and-map";
  grid-template-rows: auto 1fr;
}
.Toolbar {
  grid-area: "toolbar";
  margin: 1px;
}
.ToursAndVisitsAndMap {
  grid-area: tours-and-visits-and-map;
  height: auto !important; /* Override SplitterLayout's `100%` which breaks the layout */
  position: relative !important; /* Override SplitterLayout's `absolute` which breaks the layout */
}
.ToursAndVisits {
  display: grid;
  grid-area: tours_and_visits;
  grid-template-areas: "planned_tours unscheduled_visits";
  grid-template-columns: 1fr 230px;
  grid-template-rows: 100%;
  height: 100%;
}
.PlannedTours {
  grid-area: planned_tours;
}
.UnscheduledPlannedVisits {
  grid-area: unscheduled_visits;
}
.Map {
  height: 100%;
}
