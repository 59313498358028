.ShiftPlanning {
  display: grid;
  grid-template-rows: 30px calc(100vh - 122px);
  grid-template-columns: 50% 50%;
  width: calc(100% - 20px);
  background-color: #ffffff;
  grid-row-gap: 20px;
  margin: 0 auto;
  margin-top: 20px;
}
.ShiftPlanning > div:last-child {
  grid-column: span 2;
}
