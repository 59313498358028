.ClientMapHome {
  position: relative;
}
.SearchBar {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translate(-50%, 0);
  width: 80%;
  max-width: 500px;
}
.ClientMapMarker {
  --border-width: 4px;
  --font-size: 14px;
  --size: 28px;
}
