.ClientMapMarker {
  height: 1px;
  position: relative;
  left: 0;
  top: 0;
  width: 1px;
  --background-color: green;
  --border-box-shadow: none;
  --border-color: blue;
  --border-opacity: 1.0;
  --border-size: calc(var(--size) + 2 * var(--border-width));
  --border-width: 4px;
  --box-shadow: none;
  --color: red;
  --font-size: 12px;
  --size: 26px;
}
.ClientMapMarker.IsSelected {
  z-index: 1;
}
.ClientMapMarker:hover {
  cursor: pointer;
}
.ClientMapMarker:hover,
.ClientMapMarker.FocusTrigger.IsFocused {
  filter: brightness(115%);
  left: 1px; /* push marker visually down by offseting position by 1px */
  top: 1px;
  z-index: 1000;
}
.ClientMapMarker::before { /* A separate (virtual) element is used to allow setting a border opacity */
  border: var(--border-width) solid var(--border-color);
  border-radius: 100%;
  box-shadow: var(--border-box-shadow);
  content: '';
  height: var(--border-size);
  left: calc(-1 * var(--border-size) / 2);
  opacity: var(--border-opacity);
  position: absolute;
  top: calc(-1 * var(--border-size) / 2);
  width: var(--border-size);
}
.ClientMapMarker > .Label {
  align-items: center;
  background-color: var(--background-color);
  border-radius: 100%;
  box-shadow: var(--box-shadow);
  color: var(--color);
  display: flex;
  font-size: var(--font-size);
  font-weight: bold;
  height: var(--size);
  justify-content: center;
  left: calc(-1 * var(--size) / 2);
  opacity: 0.8;
  position: absolute;
  top: calc(-1 * var(--size) / 2);
  width: var(--size);
}
.ClientMapMarker:hover > .Label {
  opacity: 1;
}
.ClientMapMarker.IsSelected .Label {
  background: #3F5F7A;
  color: #ffffff;
  opacity: 1;
}
.ClientMapMarker > .Hint {
  bottom: calc(var(--size) / 2 - 10px);
  cursor: initial; /* The marker itself has a finger pointer which we don't want on the hint per default */
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  visibility: hidden;
  z-index: -1;
}
.ClientMapMarker.FocusTrigger.IsFocused > .Hint,
.ClientMapMarker.HoverTrigger:hover > .Hint {
  visibility: inherit;
}
.ClientDetails {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid blue;
  border-radius: 20px;
  height: 100px;
  padding: 5px;
  width: 200px;
}
.ClientDetails .Name,
.ClientDetails .Address {
  margin: 0;
}
.ClientDetails .Name {
  font-size: 12pt;
  font-weight: bold;
  margin-bottom: 5px;
}
.ClientDetails .Address {
  font-size: 10pt;
}
