.Map {
  display: grid;
  grid-template-areas:
    "search-bar"
    "internal-map";
  grid-template-rows: auto 1fr;
}
.AddressSearchBar {
  grid-area: search-bar;
  margin: 10px;
}
.InternalMap {
  grid-area: internal-map;
}
.InternalMap :global(.gm-style) {
  font: inherit !important; /* Overwrite google maps's font style which affects the markers */
}
.TourPlannedVisit {
  --background-color: #ffffff;
  --border-box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}
.TourPlannedVisit.Qualification {
  --color: var(--unknown-qualification-dark-color);
}
.TourPlannedVisit.RequiresQualifiedCaregiver {
  --color: var(--qualified-caregiver-color);
}
.TourPlannedVisit.RequiresCareCompanion {
  --color: var(--care-companion-color);
}
.TourPlannedVisit.RequiresCareCompanionWithSgbVOnlyServices {
  --color: var(--care-companion-sgb-v-only-services-color);
}
.UnscheduledPlannedVisit {
  --border-color: var(--background-color);
  --border-opacity: 0.5;
  --border-width: 6px;
  --box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  --color: #ffffff;
}
.UnscheduledPlannedVisit.Qualification {
  --background-color: var(--unknown-qualification-dark-color);
}
.UnscheduledPlannedVisit.RequiresQualifiedCaregiver {
  --background-color: var(--qualified-caregiver-color);
}
.UnscheduledPlannedVisit.RequiresCareCompanion {
  --background-color: var(--care-companion-color);
}
.UnscheduledPlannedVisit.RequiresCareCompanionWithSgbVOnlyServices {
  --background-color: var(--care-companion-sgb-v-only-services-color);
}
.PlannedActivityHint {
  width: 200px;
}
