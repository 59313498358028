.UnscheduledPlannedVisits {
  overflow-y: auto;
  padding: 4px;
  position: relative; /* Otherwise <Loader> is not position properly */
}
.Droppable {
  transition: filter 0.3s, opacity 0.3s;
}
.Droppable.Loading {
  filter: blur(1px);
  opacity: 0.5;
}
.PlannedVisit:not(:first-child) {
  margin-top: 5px;
}
