.DatePicker {
  display: inline-block !important;
  margin-bottom: 20px;
  width: 100%;
}
.Result li {
  padding-bottom: 0;
  padding-top: 0;
}
.Result li div {
  margin-bottom: 0;
  margin-top: 0;
}
