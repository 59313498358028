.PlannedTours {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 2px;
  position: relative;
}
.Toolbar {
  margin: 10px 0 0 10px;
}
.SearchBar {
  width: 100%;
}
.Grid {
  display: flex;
  flex-flow: row wrap;
  overflow-y: auto;
  padding-top: 10px;
  transition: filter 300ms, opacity 300ms;
}
.Grid.Loading {
  filter: blur(1px);
  opacity: 0.5;
}
.Grid > * {
  margin: 0 10px 20px 10px;
  width: 200px;
}
