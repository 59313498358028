.RecoEngine {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 52px);
}

.RecoEngine > .form {
  display: flex;
}

.RecoEngine > .form > .field {
  margin: 0px 10px 0px 0px;
}

.RecoEngine > .form > .field > .dropdown > .menu > .item > .Tour > div {
  margin: 2.5px 0px;
}

.RecoEngine > .form > .field > .button {
  margin-top: 23px;
}
