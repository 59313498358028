.TourOrders {
  width: 100%;
  margin: 1em 0em;
  box-shadow: none;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}
.CreatePlannedTourButton {
  align-self: flex-end;
  margin-bottom: 15px !important;
  right: 20px;
  width: 180px;
}
.TourOrdersContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 97%;
  margin: 12%;
  margin-top: 0px;
  margin-bottom: 20px;
  position: relative;
}
.Message {
  max-width: 1000px;
  margin: 0 auto !important;
}
.TourOrdersHeader, .TourOrder {
  display: grid;
  grid-template-columns: 30px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 40px;
  padding: 0 20px;
  align-items: center;
}
.TourOrdersHeader {
  height: 60px;
  font-size: 18px;
  background: linear-gradient(180deg, #00B0E7 17.19%, #0098C7 100%);
  color: #ffffff;
  font-weight: 500;
}
.TourOrdersHeader > div:first-child > div {
  width: 85%;
}
.TourOrdersHeader > div > div > input {
  box-shadow: none;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 3px;
  font-size: 12px;
  padding: 7px 10px;
  height: 30px;
}
.StartDateFilter {
  font-size: 16px;
  padding: 4px;
  width: 7em;
}
.StationFilter, .CaregiverQualificationFilter, .StatusFilter {
  font-size: 12px;
  width: 90%;
  max-width: 90%;
  white-space: nowrap !important;
}
.TourOrder {
  color: #3F5F7A;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 60px;
  font-weight: 500;
  text-decoration: none;
}
.TourOrder:hover {
  background: white;
  text-decoration: none;
}
.AcceptButton, .RejectButton {
  color: white !important;
}
.AcceptButton:hover, .RejectButton:hover {
  opacity: 0.7;
}
.FloatingAddButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5;
}
.FloatingUploadButton {
  position: fixed;
  bottom: 20px;
  right: 120px;
  z-index: 5;
}
.FloatingAddButton > button,
.FloatingUploadButton > button {
  background: #00B0E7 !important;
  font-size: 20px !important;
  color: #FFFFFF !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px !important;
}
.TourOrderButtons {
  margin-top: 20px;
}
