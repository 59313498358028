.PlannedTourAssignment {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.PlannedTourAssignment p {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 0;
}