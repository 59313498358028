.DayCell {
  cursor: pointer;
  width: 100%;
  height: 34px;
  display: flex;
  flex: 0 0 40px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-right: 2px solid #F6F6F6;
  background-color: #DEE7EE;
  color: #FFFFFF;
}
.DayCell.isWeekend {
  background-color: #DEE7EE;
}
.DayCell.isEvenRow {
}
.DayCell.isEvenRow.isWeekend {
  background-color: #E6ECF1;
}
.DayCell:hover, .DayCell.isEvenRow.isWeekend:hover {
  background-color: #B8C5D0;
}
.DayCellPopup {
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.25) !important;
}
.PopupContent {
  display: flex;
  max-width: 135px;
  flex-wrap: wrap;
  cursor: pointer;
}
.ShiftKind {
  width: 35px;
  height: 35px;
  border: 2px solid #F6F6F6;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  position: relative;
}
.ShiftKind:hover {
  transform: scale(1.2);
  transition: ease-in-out transform 300ms;
}
