.PersonAvatar {
  background: #7b7f7f;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  border-radius: 13px;
}
.PersonAvatar .user.big.icon {
  color: #ffffff;
  font-size: 1.7em;
  margin-left: 4px;
}
.PersonAvatar > div {
  background: #7b7f7f;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  border-radius: 13px;
}
