.Header {
  background-color: #00b0e7 !important;
}
.Home {
  align-items: end;
  display: grid;
  grid-template: 40px / auto auto auto;
  margin-right: 70px !important;
}
.Logo {
  height: 40px !important;
}
.MenuButton {
  color: #fff !important;
  font-size: 13px;
}
.ProfileLink {
  margin-left: auto;
}
.Title {
  margin-bottom: -3px !important;
}
.Version {
  padding-left: 3px;
} 
