.CategoryIcon {
    position: absolute;
    bottom: 5px;
    right: 3px;
    border-radius: 12px;
    height: 24px;
    width: 24px;
    background: #7B7F7F;
    padding: 3px;
    color: #ffffff !important;
}
.CategoryIcon.health {
    background: #00B0E7;
}
.CategoryIcon.visit-planning {
    background: #0D6FBA;
}
.CategoryIcon.drug-order {
    background: #47D9BF;
}