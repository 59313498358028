.Clients {
  display: flex;
  flex-direction: column;
}
.Search {
  padding: 20px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-size: 18px;
  color: #3F5F7A;
}
.Query {
  width: 400px;
}
.Headers, .ListContainer {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
}
.Headers {
  display: grid;
  grid-template-columns: 180px 60px 200px 1fr 250px 1fr 1fr 70px 80px;
  height: 60px;
  padding: 0 10px;
  align-items: center;
  font-size: 18px;
  background: linear-gradient(180deg, #00B0E7 17.19%, #0098C7 100%);
  color: #ffffff;
  font-weight: 600;
}
.ListContainer {
  box-shadow: none;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 40px;
}
