.Caregivers {
  width: calc(100vw - 20px);
  height: calc(100vh - 52px);
  display: grid;
  grid-template-rows: 100px auto;
  grid-row-gap: 20px;
  margin: 0px 10px;
}
.CaregiversSearch {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Dropdown {
  width: 400px !important;
}
.Caregiver {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  grid-column-gap: 10px;
}
