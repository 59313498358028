.TaskAvatar {
    display: flex;
    align-items:  center;
    justify-content:  center;
    position: relative;
}
.TaskAvatar .InnerAvatar {
    position: relative;
}

