.FloatingAddButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 5;
}
.FloatingAddButton .ui.circular.button {
    background: #00B0E7 !important;
    font-size: 20px;
    color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px;
}