.ClientSettings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px 250px auto;
  grid-gap: 20px;
  padding: 20px 10px;
}
.UnwantedCaregivers {
  width: 100%;
}
.UnwantedCaregiversHeader {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 900;
  padding-left: 10px;
  margin-bottom: 0;
}
.UnwantedCaregiversList {
  overflow-y: auto;
  height: calc(100% - 40px);
  border: 1px solid rgba(34,36,38,.1);
  background: white;
}
.UnwantedCaregiver {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(34,36,38,.1);
  padding: 0px 10px;
}
.DesiredTimeWindows {
  grid-column: span 2;
}
