.ClientMessages > .MainArea {
  height: 100%;
}
.ClientMessages > .MainArea .FilterButtons {
  padding: 0px 0px 10px 0px;
}
.ClientMessages  > .MainArea  > .FilterButtons > button {
  border: none;
  padding: 5px;
  background: transparent;
  color: #0D6FBA;
  opacity: 0.6;
  outline: 0;
  cursor: pointer;
}
.ClientMessages  > .MainArea  > .FilterButtons > button.active {
  font-weight: bold;
  opacity: 1;
}
