.LoggedInUser {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    padding: 10px;
    background-color: #00B0E7;
    text-align: left;
    color: #111111;
    display: flex;
    align-items: center;
}
.LoggedInUser span {
    margin-left: 4px;
}
.LoggedInUser .button.logout {
    background-color: transparent !important;
    color: #111111;
    right: 0;
    float: right;
}
.avatar {
    margin: 0;
    width: 30px;
    height: 30px;
    border-radius: 18px;
    border: solid 2px white;
    margin-right: 10px;
}
.LoggedInUser > img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid white;
}
.LoggedInUser > label {
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    font-size: 1.2em;
    margin-left: 6px;
}
