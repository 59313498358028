.PlannedVisits {
  max-width: 90%;
  margin: 40px auto;
}
.PlannedVisits.isCancelled {
  opacity: 0.3;
}
.PlannedVisitsHeader {
  width: 100%;
  height: 60px;
  margin-top: 40px;
  display: grid;
  grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr 100px;
  align-items: center;
  padding: 0 20px;
  background: linear-gradient(180deg, #00B0E7 17.19%, #0098C7 100%);
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
}
.PlannedVisits:first-child {
  margin-top: 0em;
}
.PlannedVisits:last-child {
  margin-bottom: 0em;
}
.PlannedVisits > li {
  -webkit-transition: background 0.1s ease, color 0.1s ease;
  transition: background 0.1s ease, color 0.1s ease;
  padding: 5px 0px;
}
.PlannedVisits > li:nth-child(2n) {
  background-color: rgba(0, 0, 50, 0.02);
}
.ServiceAbbreviation {
  font-weight: bold;
}
.WhileCollapsed:hover {
  cursor: pointer;
  background: #f6f6f6;
}
.WhileCollapsed{
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.WhileExpanded {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.FreeTextForm {
  margin: 30px 0;
}
.OrderInfo {
  font-size: 18px !important;
}
.Actions {
  text-align: center;
  margin: 50px 0 30px;
  display: block;
}
.Actions > button {
  font-size: 18px !important;
}
.ConfirmedButton {
  color: white !important;
  background-color: #00E794 !important;
}
.CancelButton {
  margin-right: 20px !important;
  background: #00769B !important;
  color: white !important;
}
.ConfirmedButton:hover, .CancelButton:hover {
  opacity: 0.7;
}
