.ShowTaskModal > .header,
.ShowTaskModal > .content,
.ShowTaskModal > .actions {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.ShowTaskModal .header .Metadata {
  display: flex;
  justify-content: space-between;
}
.ShowTaskModal .header .Metadata .Title {
  display: flex;
  flex-direction: column;
}
.ShowTaskModal .header .Metadata .Title h3 {
  font-size: 22px;
  font-weight: 300;
  margin: calc(2rem - .14285714em) 0 1rem;
}
.ShowTaskModal .header .Metadata .Title .ui.mini.image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.ShowTaskModal .header .Metadata .Title .Client {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.ShowTaskModal .header .Metadata .ImportantTag {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 5px;
}
.ShowTaskModal .header .Metadata button.ui.basic {
  border-radius: 20px !important;
  font-size: 12px;
}
.ShowTaskModal .header .Metadata button.ui.basic.important {
  background: #f4827c !important;
  color: #ffffff !important;
}
.ShowTaskModal .TaskDescription {
  display: flex;
  flex-direction: column;
}
.ShowTaskModal .TaskDescription .Content {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 16px;
}
.ShowTaskModal .TaskDescription .Metadata {
  color: #7b7f7f;
  font-size: 12px;
  font-weight: 100;
}
.ShowTaskModal .Subtasks {
  margin-bottom: 20px;
  margin-right: -5px;
  max-height: 350px;
  overflow-y: scroll;
  padding-bottom: 10px;
}
.ShowTaskModal textarea {
  overflow: auto;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 7px;
  outline: 0;
}
.ShowTaskModal .CommentArea {
  display: flex;
  margin-bottom: 10px;
}
.ShowTaskModal .icon {
  color: #7b7f7f;
}
.ShowTaskModal .CommentArea .PersonAvatar {
  margin-right: 10px;
}
.ShowTaskModal .CommentArea .Actions {
  display: flex;
  cursor: pointer;
}
.ShowTaskModal .CommentArea .Actions .Duedate {
  margin: 0px 10px;
}
.ShowTaskModal .CommentArea .Actions .Duedate p {
  margin-top: 4px;
}
.ShowTaskModal .TaskClosedArea .Content {
  position: relative;
  text-align: left;
  display: flex;
}
.ShowTaskModal .TaskClosedArea .Content p {
  margin-bottom: 0;
}
.ShowTaskModal .TaskClosedArea .Content i {
  color: #47D9BF;
  font-size: 22px;
}
.ShowTaskModal .TaskClosedArea .Content .Metadata {
  color: #7b7f7f;
  font-size: 12px;
  font-weight: 100;
}
.ShowTaskModal .TaskClosedArea .Actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.ShowTaskModal .TaskClosedArea .Actions p {
  color: #F16A7A;
}
