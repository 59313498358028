.App {
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  flex-flow: column !important;
  overflow-y: hidden !important;
}
.App > *:not(:first-child) {
  flex-grow: 1; /* Use full height for the embedded route component */
  width: 100%;
  height: 0;
}
