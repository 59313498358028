.Services {
  width: 450px;
  height: 100%;
}
.Header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Header > h3 {
  margin-bottom: 0px;
}
.ListHeader,
.Service {
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  padding: 0px 10px;
  align-items: center;
}
.ListHeader {
  background-color: lightgrey;
  font-size: bold;
  padding-right: 20px;
}
.Service:nth-child(2n+1) {
  background-color: white;
}
.ServicesList {
  height: calc(100% - 100px);
  width: 100%;
  overflow-y: auto;
}
