.OrderPlannedVisitModal{
  max-width: 100%;
  margin-right: 0 !important;
  background: #f2f2f2;
}
.Visible{
  opacity: 1;
}
.Hidden{
  opacity: 0;
}
.Header{
  display: block;
  text-align: center;
}
.Header > h3 {
  font-size: 30px;
  padding-top: 17px;
  margin-top: 0px;
}
.Nurse {
  max-width: 100px !important;
  margin-top: 37px;
}
.Content{
  margin: 40px 98px;
}
.Stations {
  width: 87%;
}
.TourName {
  width: 87%;
}
.Row {
  display: flex;
  margin-top: 25px;
  align-items: left;
  justify-content: space-between;
  text-align: left;
}
.StartTimePicker {
  margin-right: 20px;
}
.StartTimePicker, .EndTimePicker {
  height: 29px;
  text-align: center;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 3px;
}
.Fields {
  display: block;
}
.Fields > label {
  position: relative;
}
.Field {
  display: inline-flex;
  width: 87%;
  justify-content: space-between;
}
.CalendarIcon {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 7px;
}
.StartDatePicker, .EndDatePicker {
  height: 29px;
  text-align: center;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 3px;
  width: 87%;
}
.StartDatePickerColumn > label > div,
.StartDatePickerColumn > label > div > div,
.StartDatePickerColumn > label > div > div input {
  width: 96% !important;
}
.StartDatePickerColumn > label > div > div > div {
  width: 100%;
}
.StartDatePickerLabel {
  position: relative;
}
.Actions {
  margin-top: 66px;
  position: relative;
  width: 100%;
  text-align: center;
  padding-bottom: 60px;
}
.BookingButton {
  width: 290px;
  margin: 0 auto !important;
  border: none;
  background: #00B0E7 !important;
  border-radius: 0 !important;
  color: #fff !important;
  height: 40px;
}
