.TaskManager {
  display: grid;
  grid-template-columns: auto 400px;
  grid-template-rows: 100px calc(100vh - 152px);
  grid-template-areas:
    "header header"
    "mainArea rightSidebar";
  grid-column-gap: 30px;
  margin: 0 auto;
}
.TaskManager > .Header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.TaskManager > .Header h3 {
  color: #0D6FBA;
  margin-bottom: 5px;
}
.TaskManager > .Header > .FilterButtons > button {
  border: none;
  padding: 5px;
  background: transparent;
  color: #0D6FBA;
  opacity: 0.6;
  outline: 0;
  cursor: pointer;
}
.TaskManager > .Header > .FilterButtons > button.active {
  font-weight: bold;
  opacity: 1;
}
.TaskManager > .MainArea {
  grid-area: mainArea;
  background: #fafafa;
  height: 100%;
}
.TaskManager > .RightSidebar {
  grid-area: rightSidebar;
  background: white;
  height: 100%;
}
.TaskManager .MainInput {
  grid-area: auto;
}
.TaskManager .ui.divider {
  margin: 0px !important;
}
p.NoEntry {
  text-align: center;
  padding-top: 50px;
}
.ModalOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.85);
  left: 0;
  z-index: 3;
}
