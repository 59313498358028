.Caregiver > .input > input {
  width: 100%;
}
.Caregiver > .input > input,
.Caregiver:hover > .ui.disabled.input > input,
.Caregiver > .ui.selection.dropdown,
.Caregiver > .Checkbox > .checkbox label:before {
  min-width: unset;
  border: none;
  background: none;
}
.Caregiver:hover > .Shifts,
.Caregiver:hover > .ui.input > input,
.Caregiver.isEditable:hover > .ui.selection.dropdown,
.Caregiver:hover > .Checkbox > .checkbox label:before {
  border: 1px solid rgba(34,36,38,.15);
  background: #ffffff;
  transition: border 300ms, background 300ms;
}
.Caregiver > .checkbox {
  display: flex;
  justify-content: center;
}
.Caregiver > .ui.fitted.checkbox label {
  padding-left: 1.85714em !important; /* necessary to center the checkbox */
}
.Caregiver > .ui.selection.dropdown > .icon {
  opacity: 0;
}
.Caregiver > .button {
  opacity: 0;
  width: 36px;
  height: 36px;
  justify-self: center;
}
.Caregiver:hover > .button,
.Caregiver.isEditable:hover > .ui.selection.dropdown > .icon {
  opacity: 1;
  transition: opacity 300ms;
}
.Caregiver > .button > .label {
  visibility: hidden;
}
.Caregiver > .button:hover > .label {
  z-index: 100;
  visibility: visible;
}
.Caregiver > .Shifts {
  cursor: pointer;
  width: auto;
  height: 38px;
  padding: 10px 13px;
  border-radius: .28571429rem;
  border: none;
  background: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
