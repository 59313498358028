.VisitsOfTodayApp {
  display: flex;
  flex-direction: column;
}
.ToursContainer {
  flex-grow: 1;
  height: 0;
  overflow-y: scroll;
}
.VisitsOfTodayApp > .ToursContainer > .Tours {
  width: 100%;
}
.VisitsOfTodayApp > .ToursContainer > .Tours > .accordion.ui.styled {
  width: 100%;
}
.VisitsOfTodayApp > .ToursContainer > .Tours > .accordion > .content.active {
  padding-bottom: 0;
}
.VisitsOfTodayApp > .ToursContainer > .Tours > .accordion > .content > table > tbody > tr.open {
  background-color: #ee9090;
}
.VisitsOfTodayApp > .ToursContainer > .Tours > .accordion > .content > table > tbody > tr.inProgress {
  background-color: #eeee90;
}
.VisitsOfTodayApp > .ToursContainer > .Tours > .accordion > .content > table > tbody > tr.done {
  background-color: #90ee90;
}
