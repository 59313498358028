.PlannedTour {
  align-items: center;
  background: #f0f0f0;
  border-radius: 3px;
  margin-bottom: 5px;
  position: relative;
  transition: opacity 200ms;
}
.Loader {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  top: 60px !important;
}
.Collapsed {
  opacity: 0.5;
}
.Color {
  height: 5px;
  width: 100%;
}
.ExpandButton {
  position: absolute !important;
  right: 8px;
  top: 40px;
  transform: rotate(0deg);
  z-index: 1;
}
.ExpandButton.IsExpanded {
  transform: rotate(180deg);
}
.Expansion {
}
.PlannedActivities {
  height: 100%;
  min-height: 80px; /* Minimum space to drop planned visits when the list of visits is empty */
  transition: filter 300ms, opacity 300ms;
}
.PlannedActivities.Loading {
  filter: blur(1px);
  opacity: 0.5;
}
.PlannedActivity {
  margin-top: 5px;
}
