.ClientModal{
  max-width: 100%;
  margin-right: 0 !important;
  background: #f2f2f2;
}
.Visible{
  opacity: 1;
}
.Hidden{
  opacity: 0;
}
.Header{
  display: block;
  text-align: center;
}
.Header > h3 {
  font-size: 30px;
  padding-top: 17px;
  margin-top: 0px;
}
.Nurse {
  max-width: 100px !important;
  margin-top: 37px;
}
.Content{
  margin: 40px 98px;
}
.Content > div > div > div > div {
  width: 100%;
}
.Row {
  display: flex;
  margin-top: 25px;
  align-items: left;
  justify-content: space-between;
  text-align: left;
}
.Column textarea {
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  outline: 0;
  width: 100%;
  padding: .78571429em 1em;
}
.Actions {
  margin-top: 66px;
  position: relative;
  width: 100%;
  text-align: center;
  padding-bottom: 60px;
}
.UpdateButton {
  width: 290px;
  margin: 0 auto !important;
  border: none;
  background: #00B0E7 !important;
  border-radius: 0 !important;
  color: #fff !important;
  height: 40px;
}
