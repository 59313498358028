.LoginScreen {
  background: linear-gradient(90deg,#3f5f7a 60%,#00b0e7 0);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.LoginForm {
  display:flex;
  margin:auto;
  width: 1000px;
  height: 524px;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0px 30px 45px rgba(0, 0, 0, 0.2);
}
.MarketingContainer {
  display:flex;
  flex-direction: column;
  min-width:466px;
  height: 524px;
  border-radius: 4px 0px 0px 4px;
  background-image: url("../images/login-page-bg.png");
  background-size: cover;
}
.TaleaLogo {
  width: 111px;
  margin:15px 0 0 26px;
}
.MarketingText {
  margin-top: 265px;
}
.MarketingText > h2 {
  color: #FFFFFF;
  text-align:center;
  font-size: 40px;
  font-weight: 500;
  padding:0;
  margin:0;
}
.MarketingText > p {
  color: #FFFFFF;
  text-align:center;
  font-size: 25px;
  padding: 7px 40px;
  margin:0;
}
.LoginContainer {
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width:100%;
  height: 524px;
}
.LoginWrapper {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:361px;
}
.LoginWrapper > h3 {
  color: #3F5F7A;
  text-align:center;
  font-size: 30px;
  font-weight: 600;
  line-height: 42px;
  padding:0;
  margin:0;
}
.LoginButton {
  background: #00B0E7;
  border: 1px solid #0098C7;
  max-width: 290px !important;
  border-radius: 2px !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  text-align: center;
  color: #FFFFFF;
  margin-top: 45px !important;
}
