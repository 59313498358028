.ServicesPage {
  display: grid;
  grid-template-rows: auto auto 1fr;
}
.Toolbar {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
}
.Services {
  background-color: red;
  display: grid;
  grid-template-rows: auto 1fr;
  margin: 20px 20px 10px 20px;
}
.TableContainer {
  overflow-y: auto;
  padding: 0 0 100px 0 !important;
}
.Table {
  margin-bottom: 40px;
}
.Table thead {
  background-color: #fafafa;
}
.Table th {
  font-weight: bold !important;
}
.Table tr {
  cursor: pointer;
}
.Table tr:hover {
  background-color: #fafafa;
}
.AddButton {
  bottom: 20px;
  position: absolute !important;
  right: 40px;
}
