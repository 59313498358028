/*---------------------------
 This StyleSheet considered as
 Universal stylesheet for the entire App

 Naming Convention:
---------------------------*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro','Open Sans', sans-serif;
}
#root {
  height: 100vh;
}
a {
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}
.modal {
  height: auto;
}
.ui.blue.header {
  color: #00b0e7 !important;
}
.ui.blue.button, .ui.blue.buttons .button {
  background-color: #00b0e7 !important;;
}
.ui.segment {
  background-color: #F6F6F6;
  height: 100%;
  padding: 0;
  border: 0;
}
.ui.search.selection.dropdown .search.icon {
  padding-top: 9px !important;
}
.ui.primary.button {
  background-color: #00b0e7
}
.ui.disabled.button {
  opacity: .40 !important;
}
.ui.secondary.button {
  background-color: #ffffff;
  color: black;
  border: 1px solid #7b7f7f;
}
.ui.modal > .content {
  padding: 1rem;
}
.ui.modal > .header {
  padding: 1rem;
}
.ui.dropdown > a {
  text-decoration: none;
}
.ui.modals {
  z-index: 10000 !important; /* TODO: Remove once we stop using Semantic.Modal */
}

/*---------------------------------------------------------------------*/
/*-------------- For calendar on Order page(/orders/ ------------------*/
/*---------------------------------------------------------------------*/
.CalendarTransition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.CalendarTransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.CalendarTransition-exit {
  opacity: 1;
}
.CalendarTransition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.rbc-calendar .rbc-month-view {
  background: #ffffff;
}

.ui.checkbox {
  margin-right: 20px !important;
}
