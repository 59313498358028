.Order {
  position: relative;
  display: block;
  overflow-y: scroll;
}
.Header {
  background: white;
  height: 50px;
}
.HeaderContainer {
  display: flex;
  align-items: center;
  max-width: 90% !important;
  margin: 0px auto;
  height: 100%;
}
.Breadcrumb {
  font-size: 16px !important;
  color: #3F5F7A !important;
}
.OrderStepsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TourOrder {
  max-width: 90%;
  margin: 40px auto;
}
.TourOrder:first-child {
  margin-top: 0em;
}
.TourOrder:last-child {
  margin-bottom: 0em;
}
.TourOrder > li {
  -webkit-transition: background 0.1s ease, color 0.1s ease;
  transition: background 0.1s ease, color 0.1s ease;
  padding: 5px 0px;
}
.TourOrder > li:nth-child(2n) {
  background-color: rgba(0, 0, 50, 0.02);
}
.OrderContainer {
  background: #fff;
  font-size: 16px;
  max-width: 700px;
  margin: 45px auto;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}
.ServiceAbbreviation {
  font-weight: bold;
}
.WhileCollapsed:hover {
  cursor: pointer;
  background: #f6f6f6;
}
.WhileCollapsed{
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.WhileExpanded {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.FreeTextForm {
  margin: 30px 0;
}
.OrderInfo {
  font-size: 18px !important;
}
.Actions {
  text-align: center;
  margin: 50px 0 30px;
  display: block;
}
.Actions > button {
  font-size: 18px !important;
}
.ConfirmedButton {
  color: white !important;
  background-color: #00E794 !important;
}
.CancelButton {
  background: #00769B !important;
  color: white !important;
}
.ConfirmedButton:hover, .CancelButton:hover {
  opacity: 0.7;
}