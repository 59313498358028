.ClientInfo .ProfileAndStatus{
    text-align: center;
}
.ClientInfo .ProfileAndStatus h3 {
    margin-top: 5px;
    margin-bottom: 10px;
}
.ClientInfo .ProfileAndStatus .AvatarAndStatus{
    position: relative;
}
.ClientInfo .ProfileAndStatus h4{
   padding: 10px 0px 30px 0px;
}
.ClientInfo .ProfileAndStatus span {
    background: #7B7F7F;
    width: 25px;
    border-radius: 16px;
    color: #fff;
    position: absolute;
    bottom: 9px;
    right: 32%;
    height: 25px;
}
.ClientInfo .ProfileAndStatus span.active {
    background: #47D9BF;
}
.ClientInfo .ProfileAndStatus a.tourplanning {
    margin: 0px 0px 16px;
    display: block;
    text-align: center;
    font-size: 16px;
}
.ClientInfo .PatientGeneralInfo ul > li {
    list-style: none;
    display: flex;
    overflow: hidden;
    margin: 0 0 10px 0;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 10px;
}
.ClientInfo .PatientGeneralInfo ul > li > label {
    width: 40%;
}
.ClientInfo .PatientGeneralInfo ul > li span {
    width: 60%;
}
.ClientInfo .PatientGeneralInfo ul > li span dd label {
    opacity: 0.8;
}
.ClientInfo .PatientGeneralInfo ul > li > ul {
    display: block;
    position: relative;
    width: 60%;
}
.ClientInfo .PatientGeneralInfo ul > li > ul > li {
    display: block;
    margin-bottom: 5px;
}
.ClientInfo .PatientGeneralInfo ul > li > ul > li:last-child {
    border-bottom: none;
}
.ClientInfo .PatientGeneralInfo ul > li > ul > li dl {
    padding-bottom: 5px;
}
.ClientInfo .PatientGeneralInfo ul > li > ul > li dl dt {
    font-weight: bold;
    margin-bottom: 5px;
}
