.MonthSelector {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.MonthSelector > .SelectedMonth {
  width: 110px;
  text-align: center;
}
.MonthSelector > .button {
  margin: 0;
}
