.Feed {
  background: white;
  height: 100%;
}
.Feed .Subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  padding: 7px;
  height: 40px;
}
.Feed .Subheader .Title {
  display: flex;
  align-items: center;
}
.Feed .Subheader .Title > p {
  margin: 0;
  font-size: 18px;
}
.Feed .Subheader .Title .label {
  margin-left: 5px;
}
.Feed .Subheader .Sort {
  display: flex;
}
.Feed .Subheader .Sort > .icon {
  margin-top: 3px;
  margin-right: 0px;
  cursor: pointer;
}
.Feed .FeedItems {
  overflow-y: scroll;
  height: calc(100% - 40px);
}
