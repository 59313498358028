.Shifts {
  width: 100%;
}
.Shifts > .Headers {
  width: 100%;
  font-weight: 900;
  background-color: #cacbcd;
  padding-right: 10px;
}
.Shifts > .Headers div:nth-child(2),
.Shifts > .Headers div:nth-child(3),
.Shifts > .Headers div:nth-child(4),
.Shifts > .Headers div:nth-child(5) {
  padding-left: 13px;
}
.Shifts > a > .button {
  float: right;
  margin-top: 10px;
}
.Shifts > .ShiftsList {
  height: calc(100% - 105px);
  overflow-y: scroll;
}
.Shifts > .ShiftsList > .Shift {
  width: 100%;
}
.Shifts > .ShiftsList > .Shift:nth-child(2n) {
  background-color:  #fafafa;
}
.Shifts > .ShiftsList > .Shift.LastQualifiedShift {
  border-bottom: 1px solid black;
}
.Shifts > .ShiftsList > .button {
  margin-top: 10px;
  margin-right: 31px;
}
.Shifts > .Headers,
.Shifts > .ShiftsList > .Shift {
  display: grid;
  grid-template-columns: 1fr 5fr 5fr 2fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 5fr;
  grid-column-gap: 20px;
  align-items: center;
  height: 50px;
}
