.TaskDescription {
  display: flex;
  flex-direction: column;
}
.TaskDescription .Title {
  display: flex;
}
.TaskDescription .Title h4 {
  margin-bottom: 5px;
}
.TaskDescription .Title .label {
  position: relative;
  bottom: 8px;
  left: 3px;
  background: #00B0E7;
  color: white;
  font-size: 12px;
  padding: 5px 7px;
}
.TaskDescription .Title .label.wichtig {
  background: #f4827c;
}
.TaskDescription .Content {
  margin-bottom: 7px;
  line-height: 1.6285em;
  word-wrap: break-word;
}
.TaskDescription .Content .ClientLink::after {
  content: ' - ';
}
.TaskDescription .Metadata {
  color: #7b7f7f;
  font-size: 12px;
}
.TaskDescription .Metadata p span {
  border-bottom: 1px dashed #ccc;
}