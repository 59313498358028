.SearchBar {
  padding: 20px;
}
.SearchBar > input {
  width: 100%;
  padding: 5px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.95);
  color: #808080;
  border: 1px solid #c0c0c0;
  box-shadow: 0 3px 5px 3px rgba(127, 127, 127, 0.35);
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out,
    border 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}
.SearchBar:hover > input,
.SearchBar > input:focus {
  background-color: white;
  color: #404040;
  border: 1px solid #a0a0a0;
  box-shadow: 0 3px 5px 3px rgba(127, 127, 127, 0.75);
}
.SearchBar > .Results {
  position: relative; /* Fixes input's box-shadow being above results */
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  background-color: white;
  border: 1px solid #a0a0a0;
  box-shadow: 0 3px 5px 3px rgba(127, 127, 127, 0.75);
}
.SearchBar:hover > .Results {
  display: inherit;
  opacity: 1;
}
.SearchBar > .Results > * {
  padding: 5px;
}
.SearchBar > .Results > .Item {
  cursor: pointer;
}
.SearchBar > .Results > .Item:hover {
  background-color: #f0f0f0;
}
.SearchBar > .Results > .NoMatch {
  cursor: inherit;
}
