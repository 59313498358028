.MapMarker {
  position: relative;
  width: 0;
  height: 0;
}
.MapMarker > img {
  position: absolute;
  width: 50px;
  height: 42px;
  left: -12.5px;
  top: -40px;
  z-index: 1; /* show marker image above hint */
}
.MapMarker .Hint {
  bottom: 30px;
  left: -120px;
  position: absolute;
  visibility: hidden;
  width: 240px;
}
.MapMarker:hover > .Hint {
  visibility: inherit;
}
.MapMarker .Hint :global(.MuiCardHeader-title) {
  font-size: 14px;
}

