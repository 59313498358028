.Messages {
    background: white;
    height: 100%;
}
.Messages .ui.divider {
    margin: 0;
}
.Messages .Subheader {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    padding: 7px;
    height: 40px;
}
.Messages .Subheader > p {
    margin: 0;
    font-size: 18px;
}
.Messages .Subheader .Filter.menu {
    height: 296px;
    width: 250px;
}
.Messages .Subheader .item.active {
    font-weight: 900;
}
.Messages .Subheader .menu > .header {
    font-size: unset;
}
.Messages .Subheader .UserDropdown {
    width: 100%;
    border-radius: 0% !important;
}
.Messages .Subheader .UserDropdown .visible.menu.transition {
    position: absolute;
    top: 36px !important;
    left: 6px;
    height: 150px;
    box-shadow: none;
    border-radius: 0% !important;
}
.Messages .Subheader .SortOptions {
    display: flex;
    position: absolute;
    right: 5px;
}
.Messages .Subheader .SortOptions > .icon {
    margin-top: 3px;
    margin-right: 0px;
    cursor: pointer;
}
.Messages .Subheader .SortOptions .ui.button.active {
    background-color: #4AAFE2;
}
.Messages .MessageContainer {
    height: 100%;
    overflow-y: scroll;
}
.Messages .MessageContainer .MessageList {
    height: calc(100% - 40px);
}
