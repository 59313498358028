.CreateMessageModal .header .Top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.CreateMessageModal .header button.ui.basic {
    border-radius: 20px !important;
}
.CreateMessageModal .header button.ui.basic.important {
    background: #f4827c !important;
    color: #ffffff !important;
}
.CreateMessageModal .Client span {
    color: #888;
    font-size: 14px;
}
.CreateMessageModal .Client .ui.mini.image {
    width: 25px;
}
.CreateMessageModal .content {
    padding: 3.5rem 1.5rem !important;
}
.CreateMessageModal textarea {
    overflow: auto;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 7px;
    outline: 0;
}
.ModalContent {
    border-bottom: 1px solid rgb(224, 224, 224) !important;
    border-top: 1px solid rgb(224, 224, 224) !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}
.ModalContent .HeaderInput {
    position: absolute;
    top: 0px;
    right: 0px;
}
