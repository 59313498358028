.Toolbar {
  margin: 0 0 1px 0 !important;
}
.Toolbar :global(.MuiAccordionSummary-root) {
  align-items: start;
  display: grid !important;
  grid-template: "expand-button summary-content" / auto 1fr;
  min-height: initial !important;
  padding: 0 !important;
}
.Toolbar :global(.MuiAccordionSummary-root.Mui-focused) {
  background-color: transparent; /* Unset panel background color (set when date picker receives focus) */
}
.Toolbar :global(.MuiAccordionSummary-content) {
  grid-area: summary-content;
  margin: 0 !important;
}
.Toolbar :global(.MuiAccordionSummary-expandIcon) {
  grid-area: expand-button;
}
.Toolbar :global(.MuiAccordionSummary-expandIcon):hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.Toolbar :global(.MuiAccordionDetails-root) {
  background-color: #fafafa;
  display: grid !important;
  grid-template: auto 1fr / 1fr;
  padding: 0 !important;
}
.Chips {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 0 8px;
  width: 100%;
}
.Chips > * {
  height: 32px;
  margin-bottom: 8px; /* margin between rows when chips span multiple rows */
  margin-right: 8px;
}
.NoFilterLabel {
  align-items: center;
  color: #808080;
  display: flex;
}
.Chip > :global(.MuiChip-label) {
  font-size: 14px;
}
.Chip.Unscheduled {
  background-color: #455F78 !important;
  color: white !important;
}
.Chip.Unscheduled:focus {
  background-color: #33475a !important;
}
.Chip.Unscheduled > :global(.MuiChip-deleteIcon) {
  color: lightgray !important;
}
.Chip.Unscheduled > :global(.MuiChip-deleteIcon):hover {
  color: white !important;
}
.UnscheduledTimeRange {
  width: 480px !important;
  margin: 40px 20px 10px 20px;
}
.StationPicker, .DatePicker {
  height: 32px;
  margin: 8px 0 0 0 !important;
}
.DatePicker {
  margin-left: 8px !important;
  padding-top: 2px !important;
  width: 134px;
}
.DatePicker > :global(.MuiInput-underline)::before {
  opacity: 0; /* hides underline as long as date picker is not focused */
}
.DatePicker :global(.MuiInputAdornment-positionEnd) {
  margin-left: 0;
}
.DatePicker :global(.MuiIconButton-root) {
  opacity: 0.3;
  transition: opacity 0.1s;
}
.DatePicker:hover :global(.MuiIconButton-root),
.DatePicker :global(.MuiInput-input):active ~ :global(.MuiInputAdornment-root) :global(.MuiIconButton-root),
.DatePicker :global(.MuiInput-input):focus ~ :global(.MuiInputAdornment-root) :global(.MuiIconButton-root) {
  opacity: 1.0;
}
.Buttons {
  display: flex;
  padding: 4px;
}
.ButtonGroup {
  background-color: transparent !important;
}
.ButtonGroup:not(:first-child) {
  margin-left: 8px;
}
.ButtonGroup > :global(.MuiTypography-root) {
  margin: 0 6px;
}
