.CardContent {
  align-items: center;
  display: grid;
  grid-template-areas:
    "avatar title"
    "table table"
    "hint hint";
  grid-template-columns: 48px 1fr;
}
.Table {
  grid-area: table;
}
.Hint {
  grid-area: hint;
}
