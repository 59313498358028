.UnwantedClients {
  width: 100%;
}
.UnwantedClientsHeader {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: 900;
  padding-left: 10px;
  margin-bottom: 0;
}
.UnwantedClientsList {
  overflow-y: auto;
  max-height: 400px;
  border: 1px solid rgba(34,36,38,.1);
  background: white;
}
.UnwantedClient {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(34,36,38,.1);
  padding: 0px 10px;
}
