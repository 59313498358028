.OrderSteps {
  counter-reset: step;
  width: 800px;
}
.OrderSteps li {
  list-style-type: none;
  font-size: 18px;
  width: 25%;
  float: left;
  position: relative;
  text-align: center;
  color: #B8C5D0;
}
.OrderSteps li:before {
  position: relative;
  width: 47px;
  height: 47px;
  content: counter(step);
  color: white;
  font-weight: 800;
  font-size: 25px;
  counter-increment: step;
  line-height: 46px;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #B8C5D0;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
  z-index: 2;
}
.OrderSteps li:after {
  width: 100%;
  height: 10px;
  content: '';
  position: absolute;
  background-color: #B8C5D0;
  top: 22px;
  left: -50%;
  z-index: 1;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
}
.OrderSteps li:first-child:after {
  content: none;
}
.OrderSteps li.Active {
  color: #00B0E7;
}
.OrderSteps li.Active:before {
  width: 52px;
  height: 52px;
  border-color: white;
  background-color: #00B0E7;
  border: 5px solid #FFFFFF;
  line-height: 40px;
}
.OrderSteps li.Active:after {
  background-color: #00B0E7;
  border: 5px solid #FFFFFF;
  height: 20px;
  top: 16px;
}
.OrderSteps li.Active:last-child {
  color: #00E794;
}
.OrderSteps li.Active:last-child:before,
.OrderSteps li.Active:last-child:after {
  background: linear-gradient(0deg, #00E794, #00E794), #00B0E7
}
