.FormContent {
  margin: 40px;
  border-radius: 3px;
  text-align: center;
}
.CloudSvg {
  width: 250px !important;
  margin: 0 auto !important;
}
.DragActive {
  color: #3F5F7A;
  font-weight: 600;
  font-size: 28px;
  margin: 5px 0px;
  background: #ededed;
}
.UploadForm {
  border: 2px dashed #999;
  position: relative;
  display: block;
  padding-top: 75px;
  margin-bottom: 10px;
}
.UploadForm > h4 {
  color: #3F5F7A;
  font-weight: 600;
  font-size: 28px;
  margin: 5px 0px;
}
.UploadForm > span {
  font-size: 16px;
  color: #B8C5D0;
  text-align: center;
  display: block;
}
.UploadButtonWrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
}
.UploadButton {
  background: #00B0E7;
  border: 1px solid #0098C7;
  border-radius: 2px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  _display: inline-block;
  margin: 44px auto 0 auto;
  height: 40px;
  padding: 10px;
}
.InputField {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.PdfInputType {
  margin-top: 15px;
}
