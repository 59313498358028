.ClosingSection .Message {
    position: relative;
    text-align: left;
    display: flex;
    border-left: 0;
    padding: 0;
}
.ClosingSection .Message p {
    margin-bottom: 0;
}
.ClosingSection .Message i {
    color: #47D9BF;
    font-size: 22px;
}
.ClosingSection .Message .Metadata {
    color: #7b7f7f;
    font-size: 12px;
    font-weight: 100;
}
.ClosingSection .Actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.ClosingSection .Actions p {
    color: #F16A7A;
}
