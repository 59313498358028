.PlannedVisit {
  padding: 0 20px;
  color: #3F5F7A;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.PlannedVisit:hover {
  background: white;
}
.PlannedVisit.isExpanded {
  background: white;
}
.VisitDescription {
  display: grid;
  grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr 100px;
  align-items: center;
  height: 60px;
  cursor: pointer;
  font-size: 18px;
}
.ActionButtons {
  margin: 0 !important;
}
.PlannedVisit:hover > .ClientDescription > .ActionButtons {
  visibility: visible;
}
.PlannedVisit.isCollapsed > .ClientDescription > .ActionButtons {
  transition: transform .5s ease-in-out;
}
.PlannedVisit.isExpanded > .ClientDescription > .ActionButtons {
  visibility: visible;
  transition: transform .5s ease-in-out;
}
.PlannedVisit.isExpanded > .VisitDetails {
  width: 100%;
  height: 100%;
  display: flex;
  grid-column: span 6;
  justify-content: space-between;
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.PlannedVisit.isCollapsed > .VisitDetails {
  display: none;
  -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.Services, .VisitInfo {
  width: 48%;
  margin: 20px 0px;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.15);
}
.ServicesHeader, .VisitInfoHeader {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #00B0E7 17.19%, #0098C7 100%);
  padding-left: 20px;
  height: 40px;
  color: white;
  font-size: 18px;
}
.ServicesList {
  padding: 5px 15px;
}
.Service {
  height: 40px;
  background: #3F5F7A;
  border-radius: 2px;
  color: white;
  display: inline-block;
  padding: 10px;
  margin: 5px;
}
.VisitInfoText {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 20px;
  border: none;
  font-size: 14px;
  color: #3F5F7A;
}
.VisitInfoText::-webkit-input-placeholder {
  color: #C9C9C9;
}
/**
* ----------------------------------------
* animation slide-bottom
* ----------------------------------------
*/
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}
