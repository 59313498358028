.Message {
    border-left: 3px solid #7B7F7F;
    display: grid;
    grid-template-columns: 65px auto 80px;
    grid-template-areas: "Avatar TaskDescription TaskMetadata";
    grid-column-gap: 5px;
    padding: 10px;
    cursor: pointer;
}
.Message.new, .Message.opened {
    background: #f0f8ff;
}
.Message.health {
    border-left: 3px solid #00B0E7;
}
.Message.visit-planning {
    border-left: 3px solid #0D6FBA;
}
.Message.drug-order {
    border-left: 3px solid #47D9BF;
}
.Message:hover {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    background: #f0f0f0;
}
.Message .Avatar {
    display: flex;
    align-items:  center;
    justify-content:  center;
    position: relative;
}
.Message .Avatar .InnerAvatar {
    position: relative;
}