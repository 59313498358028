.PreviousMonth {
  width: 100%;
}
.PreviousMonth > .Headers,
.PreviousMonth > .Subheaders,
.PreviousMonth > .CaregiversList > .Caregiver
 {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr 2fr 5fr;
  grid-column-gap: 20px;
  align-items: center;
  height: 50px;
}
.PreviousMonth > .Headers {
  width: 100%;
  font-weight: 900;
  background-color: #cacbcd;
  padding: 0px 20px 0px 10px;
}
.PreviousMonth > .CaregiversList {
  height: calc(100% - 105px);
  overflow-y: scroll;
}
.PreviousMonth > .CaregiversList > .Caregiver {
  padding: 0px 10px;
}
.PreviousMonth > .CaregiversList > .Caregiver.LastQualifiedCaregiver {
  border-bottom: 1px solid black;
}
.PreviousMonth > .Headers > .Header.Day,
.PreviousMonth > .CaregiversList > .Caregiver > .HasShiftOnDay {
  justify-self: center;
}
.PreviousMonth > .Headers > .Header.Weekend {
  padding-left: 20px;
}
.PreviousMonth > .Subheaders > .Subheader {
  grid-column: 3 / span 7;
  justify-self: center;
}
.PreviousMonth > .CaregiversList > .Caregiver > .ShiftOnDay6 {
  min-width: 100px;
  justify-self: center;
}
.PreviousMonth > a > .button {
  float: right;
  margin-top: 10px;
  margin-right: 0;
}

